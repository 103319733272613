/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import { lazy } from 'react';

export const AdminView = lazy(() => import('./AdminView'));
export const AlertListView = lazy(() => import('./AlertListView'));
export const LoginView = lazy(() => import('./LoginView'));
export const NurseReportView = lazy(() => import('./NurseReportView'));
export const SleepReportView = lazy(() => import('./SleepReportView'));
export const ReportsView = lazy(() => import('./ReportsView'));
export const AlertSettingsListView = lazy(() => import('./AlertSettingsListView'));
export const AlertSettingsEditView = lazy(() => import('./AlertSettingsEditView'));
export const AlertSettingsView = lazy(() => import('./AlertSettingsView'));
export const AlertView = lazy(() => import('./AlertView'));
export const BaseView = lazy(() => import('./BaseView'));
export const CameraEditView = lazy(() => import('./CameraEditView'));
export const CameraListView = lazy(() => import('./CameraListView'));
export const CameraView = lazy(() => import('./CameraView'));
export const CareCenterEditView = lazy(() => import('./CareCenterEditView'));
export const DashboardView = lazy(() => import('./DashboardView'));
export const DevContextView = lazy(() => import('./DevContextView'));
export const DeviceEditView = lazy(() => import('./DeviceEditView'));
export const DeviceListView = lazy(() => import('./DeviceListView'));
export const DeviceView = lazy(() => import('./DeviceView'));
export const DevView = lazy(() => import('./DevView'));
export const HomeView = lazy(() => import('./HomeView'));
export const LocationsView = lazy(() => import('./LocationsView'));
export const NurseCallEditView = lazy(() => import('./NurseCallEditView'));
export const NurseCallListView = lazy(() => import('./NurseCallListView'));
export const NurseCallView = lazy(() => import('./NurseCallView'));
export const PageNotFound = lazy(() => import('./PageNotFound'));
export const ResidentEditView = lazy(() => import('./ResidentEditView'));
export const ResidentListView = lazy(() => import('./ResidentListView'));
export const ResidentView = lazy(() => import('./ResidentView'));
export const VitalsReportView = lazy(() => import('./VitalsReportView'));
export const UserProfileView = lazy(() => import('./UserProfileView'));
export const UserSettingsView = lazy(() => import('./UserSettingsView'));
export const AboutView = lazy(() => import('./AboutView'));
