"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isCamera = exports.isCameraModel = exports.CameraMode = void 0;
var CameraMode;
(function (CameraMode) {
    CameraMode["Cloud"] = "Cloud";
    CameraMode["UrlStyle"] = "UrlStyle";
    CameraMode["Local"] = "Local";
})(CameraMode || (exports.CameraMode = CameraMode = {}));
// type guards
function isCameraModel(object) {
    const _obj = object;
    if (_obj.cameraType === 'Elmo') {
        return _obj.cameraId !== undefined;
    }
    else {
        return _obj.cameraId !== undefined && _obj.currentPhotoTime >= 0;
    }
}
exports.isCameraModel = isCameraModel;
function isCamera(object) {
    const _obj = object;
    return _obj.data !== undefined && isCameraModel(_obj.data);
}
exports.isCamera = isCamera;
