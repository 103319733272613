"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.isSnapshotCamera = exports.isVideoStreamCamera = void 0;
const url_1 = require("url");
const logger_1 = require("../logger");
const models_1 = require("../models");
const store = __importStar(require("./DatabaseOps"));
const logger = (0, logger_1.getLogger)('/services/CameraService', 'info');
logger.debug();
class CameraService {
    constructor(db) {
        this.camera = (snapshot) => {
            return { snapshot, id: snapshot.id, data: this.data(snapshot) };
        };
        this.refresh = (camera) => __awaiter(this, void 0, void 0, function* () {
            const r = yield this.findById(camera.snapshot.id);
            if (r) {
                return this.camera(r.snapshot);
            }
        });
        this.data = (snapshot) => {
            const data = this.db.data(snapshot);
            if (data !== undefined) {
                // perform any necessary updates...
                if (data.blur === undefined) {
                    data.blur = true;
                }
                if (data.cameraType === undefined) {
                    data.cameraType = 'IOData';
                }
                if (data.mute === undefined) {
                    data.mute = true;
                }
            }
            return data;
        };
        this.load = (conditions) => {
            return this.db.load(models_1.Collection.cameras, null, conditions).then((snapshots) => {
                const cameras = [];
                snapshots.forEach((snapshot) => {
                    cameras.push(this.camera(snapshot));
                });
                return cameras;
            });
        };
        this.findById = (id) => this.db.findById(models_1.Collection.cameras, id);
        this.findByCameraId = (cameraId) => __awaiter(this, void 0, void 0, function* () {
            const cameras = yield this.load([store.where.eq('cameraId', cameraId)]);
            if (cameras.length > 0) {
                return cameras[0];
            }
            return undefined;
        });
        this.findByRef = (ref) => this.db.findByRef(ref);
        this.getCurrentPhotoUrl = (camera) => __awaiter(this, void 0, void 0, function* () {
            if ((0, exports.isVideoStreamCamera)(camera)) {
                return undefined;
            }
            else {
                const token = yield this.db.jwtToken();
                return new url_1.URL(this.db.servicesUrl + '/camera/' + camera.data.cameraId + '?token=' + token);
            }
        });
        this.setCurrentPhotoTime = (camera, time) => {
            camera.data.currentPhotoTime = time;
            return this.updateCamera(camera);
        };
        this.setIpAddress = (camera, ipaddress) => {
            camera.data.ipAddress = ipaddress;
            return this.updateCamera(camera);
        };
        this.getCareCenter = (camera) => this.db.careCenters.findByRef(camera.data.careCenterRef);
        this.getResident = (camera) => __awaiter(this, void 0, void 0, function* () {
            if (camera.data.residentRef) {
                return this.db.residents.findByRef(camera.data.residentRef);
            }
            return undefined;
        });
        this.getCameras = (center) => this.load(center ? [store.where.eq('careCenterRef', center.snapshot.ref.path)] : []);
        this.getResidentCameras = (resident) => this.load([store.where.eq('residentRef', resident.snapshot.ref.path)]);
        this.getUnassignedCameras = (center) => this.load([
            store.where.eq('residentRef', null),
            store.where.eq('careCenterRef', center.snapshot.ref.path),
        ]);
        this.getPeekDuration = (camera) => __awaiter(this, void 0, void 0, function* () {
            const center = yield this.getCareCenter(camera);
            if (center) {
                return this.db.careCenters.getCameraPeekDuration(center);
            }
            return 30; // 30 seconds
        });
        this.getAlertDuration = (camera) => __awaiter(this, void 0, void 0, function* () {
            const center = yield this.getCareCenter(camera);
            if (center) {
                return this.db.careCenters.getCameraAlertDuration(center);
            }
            return 15 * 60; // 15 minutes
        });
        this.getCameraMode = (camera) => __awaiter(this, void 0, void 0, function* () {
            const center = yield this.getCareCenter(camera);
            if (center) {
                return this.db.careCenters.getCameraMode(center);
            }
            return 'Cloud';
        });
        this.getOnlineStatus = (camera) => __awaiter(this, void 0, void 0, function* () {
            const cameraIp = camera.data.ipAddress;
            const url = `http://${cameraIp}`;
            if (cameraIp === undefined) {
                return false;
            }
            try {
                const response = yield fetch(url, {
                    method: 'GET',
                    mode: 'no-cors',
                });
                return response.type === 'opaque';
            }
            catch (error) {
                logger.info(`Error pinging camera IP ${cameraIp}:`, error);
                return false;
            }
        });
        this.addCamera = (data) => this.db.add(models_1.Collection.cameras, data);
        this.updateCamera = (camera) => this.db.update(camera);
        this.deleteCamera = (camera) => {
            if (camera.snapshot) {
                return this.db.delete(camera);
            }
            else {
                return Promise.reject('cannot delete: camera has no snapshot');
            }
        };
        this.hardDeleteCamera = (camera) => {
            if (camera.snapshot) {
                return this.db.hardDelete(camera);
            }
            else {
                return Promise.reject('cannot delete: camera has no snapshot');
            }
        };
        /**
         * Listen for camera changes - triggered when new image is available
         * @param nameSpace string the name space for the listener. Only one listener per camera per name space is allowed.
         */
        this.listen = (nameSpace, sensor, changed) => this.db.listeners.listen(nameSpace, sensor.snapshot, (snapshot) => {
            const _camera = this.camera(snapshot);
            if (_camera.data !== undefined) {
                changed(_camera);
            }
        });
        /**
         * Stop listening for camera changes.
         */
        this.stopListening = (listener) => this.db.listeners.stop(listener);
        this.db = db;
    }
}
const isVideoStreamCamera = (camera) => {
    return camera.data.cameraType === 'Elmo';
};
exports.isVideoStreamCamera = isVideoStreamCamera;
const isSnapshotCamera = (camera) => {
    return !(0, exports.isVideoStreamCamera)(camera);
};
exports.isSnapshotCamera = isSnapshotCamera;
// export const isUrlStyleCamera = (camera: Camera) => {
//   return camera.data.cameraMode === 'UrlStyle';
// };
// export const isLocalCamera = (camera: Camera) => {
//   return camera.data.cameraMode === 'Local';
// };
exports.default = CameraService;
