import { ACErrorCodes, ACErrorCodesEnum, isACError } from 'attentive-connect-store/dist/errors';
import { isErrorMessage } from 'attentive-connect-store/dist/errors/ErrorMessage';
import * as models from 'attentive-connect-store/dist/models';
import { isFirestoreError } from 'attentive-connect-store/dist/services/DatabaseOps';
import { AlarmDetailTypeEnum } from 'attentive-connect-store/dist/types/aisleepClient';
import { IntlShape } from 'react-intl';
import { isError } from 'underscore';
import { isVendorToppan } from '../Vendor';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type MessageValues = Record<string, any> | undefined;
const _translate = (intl: IntlShape, id: string, values?: MessageValues): string => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const message = intl.formatMessage({ id }, values);

  return message;
};

export class StringsIntl {
  intl: IntlShape;

  about = {
    about: () => _translate(this.intl, 'about'),
    title: () => _translate(this.intl, isVendorToppan() ? 'about/title/toppan' : 'about/title'),
    upToDate: () =>
      _translate(this.intl, isVendorToppan() ? 'about/up-to-date/toppan' : 'about/up-to-date'),
    version: (version: string, build: string) =>
      _translate(this.intl, 'about/version', {
        version,
        build,
      }),
    versionVersion: (version: string) =>
      _translate(this.intl, 'about/version-version', {
        version,
      }),
    versionBuild: (build: string) =>
      _translate(this.intl, 'about/version-build', {
        build,
      }),
    relaunch: () => _translate(this.intl, 'about/relaunch'),
    relaunchMessage: () =>
      _translate(
        this.intl,
        isVendorToppan() ? 'about/relaunch-message/toppan' : 'about/relaunch-message'
      ),
    updating: () => _translate(this.intl, 'about/updating'),
  };

  auth = {
    error: (firebaseError?: string) => {
      if (firebaseError) {
        return _translate(this.intl, firebaseError);
      } else {
        return _translate(this.intl, 'auth/error');
      }
    },
  };

  action = {
    addCareCenter: () => _translate(this.intl, 'action/add-care-center'),
    addResident: () => _translate(this.intl, 'action/add-resident'),
    cancel: () => _translate(this.intl, 'action/cancel'),
    change: () => _translate(this.intl, 'action/change'),
    changeLocation: () => _translate(this.intl, 'action/change-location'),
    close: () => _translate(this.intl, 'action/close'),
    back: () => _translate(this.intl, 'action/back'),
    delete: () => _translate(this.intl, 'action/delete'),
    download: () => _translate(this.intl, 'action/download'),
    edit: () => _translate(this.intl, 'action/edit'),
    next: () => _translate(this.intl, 'action/next'),
    of: () => _translate(this.intl, 'action/of'),
    previous: () => _translate(this.intl, 'action/previous'),
    refresh: () => _translate(this.intl, 'action/refresh'),
    reset: () => _translate(this.intl, 'action/reset'),
    resolve: () => _translate(this.intl, 'action/resolve'),
    nurseCall: () => _translate(this.intl, 'action/nurse-call'),
    supporting: () => _translate(this.intl, 'action/supporting'),
    save: () => _translate(this.intl, 'action/save'),
    search: () => _translate(this.intl, 'action/search'),
    searchList: () => _translate(this.intl, 'action/search-list'),
    searchElipsis: () => _translate(this.intl, 'action/search-elipsis'),
    selectLocation: () => _translate(this.intl, 'action/select-location'),
    submit: () => _translate(this.intl, 'action/submit'),
    upload: () => _translate(this.intl, 'action/upload'),
    uploadProgress: () => _translate(this.intl, 'action/upload-progress'),
    residentVitalsReport: () => _translate(this.intl, 'action/resident-vitals-report'),
  };

  alertGenerator = {
    describeHeartAbove: (bpm: number, secs: number) => {
      if (secs < 60) {
        return _translate(
          this.intl,
          'alert-generator/alert-description/above-x-bpm-for-over-y-seconds',
          {
            x: bpm,
            y: secs,
          }
        );
      } else {
        const mins = Math.round(secs / 60);
        return _translate(
          this.intl,
          'alert-generator/alert-description/above-x-bpm-for-over-y-minutes',
          {
            x: bpm,
            y: mins,
          }
        );
      }
    },
    describeHeartBelow: (bpm: number, secs: number) => {
      if (secs < 60) {
        return _translate(
          this.intl,
          'alert-generator/alert-description/below-x-bpm-for-over-y-seconds',
          {
            x: bpm,
            y: secs,
          }
        );
      } else {
        const mins = Math.round(secs / 60);
        return _translate(
          this.intl,
          'alert-generator/alert-description/below-x-bpm-for-over-y-minutes',
          {
            x: bpm,
            y: mins,
          }
        );
      }
    },
    describeBreathingAbove: (rpm: number, secs: number) => {
      if (secs < 60) {
        return _translate(
          this.intl,
          'alert-generator/alert-description/above-x-rpm-for-over-y-seconds',
          {
            x: rpm,
            y: secs,
          }
        );
      } else {
        const mins = Math.round(secs / 60);
        return _translate(
          this.intl,
          'alert-generator/alert-description/above-x-rpm-for-over-y-minutes',
          {
            x: rpm,
            y: mins,
          }
        );
      }
    },
    describeBreathingBelow: (rpm: number, secs: number) => {
      if (secs < 60) {
        return _translate(
          this.intl,
          'alert-generator/alert-description/below-x-rpm-for-over-y-seconds',
          {
            x: rpm,
            y: secs,
          }
        );
      } else {
        const mins = Math.round(secs / 60);
        return _translate(
          this.intl,
          'alert-generator/alert-description/below-x-rpm-for-over-y-minutes',
          {
            x: rpm,
            y: mins,
          }
        );
      }
    },
    describeFall: (secs: number) => {
      const mins = Math.round(secs / 60);

      return _translate(this.intl, 'alert-generator/alert-description/fall-detected', { x: mins });
    },
  };

  alertTypes = {
    heart: () => _translate(this.intl, 'alert-types/heart'),
    fall: () => _translate(this.intl, 'alert-types/fall'),
    sitting: () => _translate(this.intl, 'alert-types/sitting'),
    fallDetected: () => _translate(this.intl, 'alert-types/fall-detected'),
    breathing: () => _translate(this.intl, 'alert-types/breathing'),
    turnover: () => _translate(this.intl, 'alert-types/turnover'),
    movement: () => _translate(this.intl, 'alert-types/movement'),
    nurseCall: () => _translate(this.intl, 'alert-types/nurse-call'),
    health: () => _translate(this.intl, 'alert-types/health'),
  };

  alertSettings = {
    heart: () => _translate(this.intl, 'alert-settings/heart'),
    fall: () => _translate(this.intl, 'alert-settings/fall'),
    breathing: () => _translate(this.intl, 'alert-settings/breathing'),
    turnover: () => _translate(this.intl, 'alert-settings/turnover'),
    movement: () => _translate(this.intl, 'alert-types/movement'),
    health: () => _translate(this.intl, 'alert-settings/health'),
    nurseCall: () => _translate(this.intl, 'alert-types/nurse-call'),
  };

  unit = {
    megabyte: (val: string | number) => _translate(this.intl, 'unit/mega-byte', { VALUE: val }),
    signalStrengthDbm: (val: string | number) =>
      _translate(this.intl, 'unit/signal-strength-dbm', { VALUE: val }),
    percent: (val: string | number) => _translate(this.intl, 'unit/percent', { VALUE: val }),
    minutes: (val: string | number) => _translate(this.intl, 'unit/minutes', { VALUE: val }),
    hours: (val: string | number) => _translate(this.intl, 'unit/hours', { VALUE: val }),
    days: (val: string | number) => _translate(this.intl, 'unit/days', { VALUE: val }),
  };

  alerts = {
    actions: {
      OTHER: () => _translate(this.intl, 'alerts/actions/other'),
      VISUAL_CONFIRM: () => _translate(this.intl, 'alerts/actions/visual-confirm'),
      METER_CONFIRM: () => _translate(this.intl, 'alerts/actions/meter-confirm'),
      NO_CONFIRM: () => _translate(this.intl, 'alerts/actions/no-confirm'),
    },

    advancedOptions: () => _translate(this.intl, 'alerts/advanced-options'),
    alert: () => _translate(this.intl, 'alerts/alert'),
    details: () => _translate(this.intl, 'alerts/details'),
    resolution: () => _translate(this.intl, 'alerts/resolution'),
    editResolution: () => _translate(this.intl, 'alerts/resolution-edit'),
    situation: () => _translate(this.intl, 'alerts/situation'),
    actionTaken: () => _translate(this.intl, 'alerts/action-taken'),
    careProvided: () => _translate(this.intl, 'alerts/care-provided'),
    alertLeavingBed: () => _translate(this.intl, 'alerts/alert-leaving-bed'),
    breathingCustomBetween: () => _translate(this.intl, 'alerts/breathing-between'),
    customFallMinutes: () => _translate(this.intl, 'alerts/custom-fall-minutes'),
    customTurnoverMinutes: () => _translate(this.intl, 'alerts/custom-turnover-minutes'),
    duration: () => _translate(this.intl, 'alerts/duration'),
    mode: () => _translate(this.intl, 'alerts/mode'),
    movement: () => _translate(this.intl, 'alerts/movement'),
    exceedsRange: () => _translate(this.intl, 'alerts/exceeds-range'),
    earlyAlertAfter1Minute: () => _translate(this.intl, 'alerts/early-alert-after-1-minute'),
    earlyAlertLeaveBed: () => _translate(this.intl, 'alerts/early-alert-leave-bed'),
    expiredStatus: () => _translate(this.intl, 'alerts/expired-status'),
    returnedExpiredStatus: () => _translate(this.intl, 'alerts/returned-expired-status'),
    canceledStatus: () => _translate(this.intl, 'alerts/canceled-status'),
    expiredNote: () => _translate(this.intl, 'alerts/expired-note'),
    returnedExpiredNote: () => _translate(this.intl, 'alerts/returned-expired-note'),
    fallAlertDuringWakeupHours: () =>
      _translate(this.intl, 'alerts/fall-alert-during-wakeup-hours'),
    resolutionIsInProgressWarning: () =>
      _translate(this.intl, 'alerts/resolution-is-in-progress-warning'),

    nurseCall: () => _translate(this.intl, 'alert-types/nurse-call'),

    // nurseCall: {
    //   count: (count: number) => _translate(this.intl, 'alerts/nurse-call', { count }),
    // },

    fall: {
      bedExitWait: (minutes: string | number) =>
        _translate(this.intl, 'alerts/fall/bed-exit-wait', { MINUTES: minutes }),
    },

    hr: {
      duration: (seconds: string | number) =>
        _translate(this.intl, 'alerts/hr/duration', { SECONDS: seconds }),
      percent: (percent: string | number) =>
        _translate(this.intl, 'alerts/hr/percent', { PERCENT: percent }),
      resume: (seconds: string | number) =>
        _translate(this.intl, 'alerts/hr/resume', { SECONDS: seconds }),
    },

    rr: {
      duration: (seconds: string | number) =>
        _translate(this.intl, 'alerts/rr/duration', { SECONDS: seconds }),
      percent: (percent: string | number) =>
        _translate(this.intl, 'alerts/rr/percent', { PERCENT: percent }),
      resume: (seconds: string | number) =>
        _translate(this.intl, 'alerts/rr/resume', { SECONDS: seconds }),
    },

    heartCustomBetween: () => _translate(this.intl, 'alerts/heart-between'),
    noAlerts: () => _translate(this.intl, 'alerts/no-alerts'),
    noAlertSettings: () => _translate(this.intl, 'alerts/no-alert-settings'),

    notes: {
      turnBack: () => _translate(this.intl, 'alerts/notes/turn-back'),
      turnLeft: () => _translate(this.intl, 'alerts/notes/turn-left'),
      turnRight: () => _translate(this.intl, 'alerts/notes/turn-right'),
    },

    noomi: {
      fall: {
        returnToBed: () => _translate(this.intl, 'alerts/noomi/fall/return-to-bed'),
        bex: () => _translate(this.intl, 'alerts/noomi/fall/bex'),
        bexDescription: () => _translate(this.intl, 'alerts/noomi/fall/bex-description'),
        bexDescription2: () => _translate(this.intl, 'alerts/noomi/fall/bex-description-2'),
        alertEnabledTime: (time: string | number) =>
          _translate(this.intl, 'alerts/noomi/fall/alert-enabled-time', { X: time }),
        bexSensitivity: () => _translate(this.intl, 'alerts/noomi/fall/bex-sensitivity'),
        maxTimeOutOfBed: () => _translate(this.intl, 'alerts/noomi/fall/max-time-out-of-bed'),
        maxTimeOutOfBedDescription: () =>
          _translate(this.intl, 'alerts/noomi/fall/max-time-out-of-bed-description'),
        maxTimeOutOfBedDescription2: () =>
          _translate(this.intl, 'alerts/noomi/fall/max-time-out-of-bed-description-2'),
      },
      hr: {
        outOfRange: () => _translate(this.intl, 'alerts/noomi/hr/out-of-range'),
        outOfRangeDescription: () =>
          _translate(this.intl, 'alerts/noomi/hr/out-of-range-description'),
        range: () => _translate(this.intl, 'alerts/noomi/hr/range'),
      },
      rr: {
        outOfRange: () => _translate(this.intl, 'alerts/noomi/rr/out-of-range'),
        outOfRangeDescription: () =>
          _translate(this.intl, 'alerts/noomi/rr/out-of-range-description'),
        range: () => _translate(this.intl, 'alerts/noomi/rr/range'),
      },
      turn: {
        turnTimerExceeded: () => _translate(this.intl, 'alerts/noomi/turn/turn-timer-exceeded'),
        turnTimerExceededDescription: () =>
          _translate(this.intl, 'alerts/noomi/turn/turn-timer-exceeded-description'),
        intervalInHours: () => _translate(this.intl, 'alerts/noomi/turn/interval-in-hours'),
      },
      health: {
        selectInclude: () => _translate(this.intl, 'alerts/noomi/health/select-include'),
      },
      off: () => _translate(this.intl, 'alerts/noomi/off'),
      on: () => _translate(this.intl, 'alerts/noomi/on'),
      offDescription: () => _translate(this.intl, 'alerts/noomi/off-description'),
      hrOffDescription: () => _translate(this.intl, 'alerts/noomi/hr-off-description'),
      rrOffDescription: () => _translate(this.intl, 'alerts/noomi/rr-off-description'),
      hrAbove: (bpm: string | number) => _translate(this.intl, 'alerts/noomi/hr-above', { X: bpm }),
      hrBelow: (bpm: string | number) => _translate(this.intl, 'alerts/noomi/hr-below', { X: bpm }),
      rrAbove: (rpm: string | number) => _translate(this.intl, 'alerts/noomi/rr-above', { X: rpm }),
      rrBelow: (rpm: string | number) => _translate(this.intl, 'alerts/noomi/rr-below', { X: rpm }),
      bedExit: () => _translate(this.intl, 'alerts/noomi/bed-exit'),
      longBedExit: (duration: string | number) =>
        _translate(this.intl, 'alerts/noomi/long-bed-exit', { X: duration }),
      respiratoryDepression: () => _translate(this.intl, 'alerts/noomi/respiratory-depression'),
      turnTimerExpired: () => _translate(this.intl, 'alerts/noomi/turn-timer-expired'),
      turnNotVerified: () => _translate(this.intl, 'alerts/noomi/turn-not-verified'),
      lowMotionAtNight: () => _translate(this.intl, 'alerts/noomi/low-motion-at-night'),
      reassessBraden: () => _translate(this.intl, 'alerts/noomi/reasses-braden'),
      unstableSignalLevel: () => _translate(this.intl, 'alerts/noomi/unstable-signal-level'),
      trendIndication: () => _translate(this.intl, 'alerts/noomi/trend-indication'),
      multiParameter: () => _translate(this.intl, 'alerts/noomi/multi-parameter'),
      noMotion: () => _translate(this.intl, 'alerts/noomi/no-motion'),
      bedSensorExpired: () => _translate(this.intl, 'alerts/noomi/bed-sensor-expired'),
      bedSensorExpiringSoon: () => _translate(this.intl, 'alerts/noomi/bed-sensor-expiring-soon'),
      deviceDisconnected: () => _translate(this.intl, 'alerts/noomi/device-disconnected'),
      hardDriveFull: () => _translate(this.intl, 'alerts/noomi/hard-drive-full'),
      sensorDisconnected: () => _translate(this.intl, 'alerts/noomi/sensor-disconnected'),
      unitMalfunction: () => _translate(this.intl, 'alerts/noomi/unit-malfunction'),
      unsupported: (type?: unknown) =>
        _translate(this.intl, 'alerts/noomi/unsupported', { CLINICAL_ALERT: type ? type : '' }),
    },

    aisleep: {
      hrAbove: (bpm: string | number) =>
        _translate(this.intl, 'alerts/aisleep/hr-above', { X: bpm }),
      hrBelow: (bpm: string | number) =>
        _translate(this.intl, 'alerts/aisleep/hr-below', { X: bpm }),
      rrAbove: (rpm: string | number) =>
        _translate(this.intl, 'alerts/aisleep/rr-above', { X: rpm }),
      rrBelow: (rpm: string | number) =>
        _translate(this.intl, 'alerts/aisleep/rr-below', { X: rpm }),
      departure: () => _translate(this.intl, 'alerts/aisleep/departure'),
      moving: () => _translate(this.intl, 'alerts/aisleep/moving'),
      movementDescription: () => _translate(this.intl, 'alerts/aisleep/movement-description'),
      bedExit: () => _translate(this.intl, 'alerts/aisleep/bed-exit'),
      respiratoryDepression: () => _translate(this.intl, 'alerts/aisleep/respiratory-depression'),
      sitting: () => _translate(this.intl, 'alerts/aisleep/sitting'),
      unsupported: (type?: AlarmDetailTypeEnum) =>
        _translate(this.intl, 'alerts/unsuported/unsupported', { ALERT: type ? type : '' }),
      offDescription: () => _translate(this.intl, 'alerts/noomi/off-description'),
      hrOffDescription: () => _translate(this.intl, 'alerts/noomi/hr-off-description'),
      rrOffDescription: () => _translate(this.intl, 'alerts/noomi/rr-off-description'),
      movementOffDescription: () =>
        _translate(this.intl, 'alerts/aisleep/movement-off-description'),
      onDescription: () => _translate(this.intl, 'label/alerts-enabled'),
    },

    notReturned: () => _translate(this.intl, 'alerts/not-returned'),
    open: () => _translate(this.intl, 'alerts/open'),
    snoozed: () => _translate(this.intl, 'alerts/snoozed'),
    preventiveFallNote: () => _translate(this.intl, 'alerts/preventive-fall-note'),
    replacedStatus: () => _translate(this.intl, 'alerts/replaced-status'),
    replacedNote: () => _translate(this.intl, 'alerts/replaced-note'),
    resident: () => _translate(this.intl, 'alerts/resident'),
    resolved: () => _translate(this.intl, 'alerts/resolved'),
    resolvedBy: () => _translate(this.intl, 'alerts/resolved-by'),
    resolveFailed: () => _translate(this.intl, 'alerts/resolve-failed'),
    resolveSuccess: () => _translate(this.intl, 'alerts/resolve-success'),

    selectWhen: () => _translate(this.intl, 'alerts/select-when'),

    settings: {
      settings: () => _translate(this.intl, 'alerts/settings'),
      range: {
        range: () => _translate(this.intl, 'alerts/settings/range/range'),
        between: () => _translate(this.intl, 'alerts/settings/range/between'),
        cooloff: () => _translate(this.intl, 'alerts/settings/range/cooloff'),
        duration: () => _translate(this.intl, 'alerts/settings/range/duration'),
        max: () => _translate(this.intl, 'alerts/settings/range/max'),
        min: () => _translate(this.intl, 'alerts/settings/range/min'),
        percent: () => _translate(this.intl, 'alerts/settings/range/percent'),
      },
    },

    time: () => _translate(this.intl, 'alerts/time'),
    timeRange: () => _translate(this.intl, 'alerts/time-range'),
    afterBedExit: () => _translate(this.intl, 'alerts/alerttime-after-bed-exit'),
    today: () => _translate(this.intl, 'alerts/today'),
    turnoverAlertOnlyIfNotTurned: () => _translate(this.intl, 'alerts/turn-alert-not-turned'),
    turnoverCustomTimeInBed: () => _translate(this.intl, 'alerts/alert-custom-time-in-bed'),
    turnReminder2Hour: () => _translate(this.intl, 'alerts/turn-reminder-2-hour'),
    turnReminder3Hour: () => _translate(this.intl, 'alerts/turn-reminder-3-hour'),
    highSensitive: () => _translate(this.intl, 'alerts/high-sensitive'),
    middleSensitive: () => _translate(this.intl, 'alerts/middle-sensitive'),
    lowSensitive: () => _translate(this.intl, 'alerts/low-sensitive'),
    highSensitiveDescription: () => _translate(this.intl, 'alerts/high-sensitive-description'),
    middleSensitiveDescription: () => _translate(this.intl, 'alerts/middle-sensitive-description'),
    lowSensitiveDescription: () => _translate(this.intl, 'alerts/low-sensitive-description'),
    descriptionHighSensitive: () => _translate(this.intl, 'alerts/description-high-sensitive'),
    descriptionMiddleSensitive: () => _translate(this.intl, 'alerts/description-middle-sensitive'),
    descriptionLowSensitive: () => _translate(this.intl, 'alerts/description-low-sensitive'),
    moveOff: () => _translate(this.intl, 'alerts/move-off'),
    moveOn: () => _translate(this.intl, 'alerts/move-on'),
    nurseCallOff: () => _translate(this.intl, 'alerts/nurse-call-off'),
    nurseCallOn: () => _translate(this.intl, 'alerts/nurse-call-on'),
    nursecallOffDescription: () =>
      _translate(this.intl, 'alerts/nurse-call/nurse-call-off-description'),
  };

  timeZone = {
    timeZone: () => this.label.timeZone(),
    local: () => _translate(this.intl, 'time-zone/local'),
    patient: () => _translate(this.intl, 'time-zone/patient'),
  };

  biobeat = {
    deviceId: () => _translate(this.intl, 'biobeat/device-id'),
    timeZone: () => _translate(this.intl, 'biobeat/time-zone'),
    name: () => _translate(this.intl, 'biobeat/name'),
    biobeat: () => _translate(this.intl, 'biobeat/name'),
    deviceConfig: () => _translate(this.intl, 'biobeat/device-config'),
    bp: () => _translate(this.intl, 'biobeat/vitals/bp'),
    bpUnit: () => _translate(this.intl, 'biobeat/vitals/bp/unit'),
    br: () => _translate(this.intl, 'biobeat/vitals/br'),
    brUnit: () => _translate(this.intl, 'biobeat/vitals/br/unit'),
    calories: () => _translate(this.intl, 'biobeat/vitals/calories'),
    caloriesUnit: () => _translate(this.intl, 'biobeat/vitals/calories/unit'),
    ci: () => _translate(this.intl, 'biobeat/vitals/ci'),
    ciUnit: () => _translate(this.intl, 'biobeat/vitals/ci/unit'),
    co: () => _translate(this.intl, 'biobeat/vitals/co'),
    coUnit: () => _translate(this.intl, 'biobeat/vitals/co/unit'),
    hr: () => _translate(this.intl, 'biobeat/vitals/hr'),
    hrUnit: () => _translate(this.intl, 'biobeat/vitals/hr/unit'),
    hrv: () => _translate(this.intl, 'biobeat/vitals/hrv'),
    hrvUnit: () => _translate(this.intl, 'biobeat/vitals/hrv/unit'),
    movement: () => _translate(this.intl, 'biobeat/vitals/movement'),
    movementUnit: () => _translate(this.intl, 'biobeat/vitals/movement/unit'),
    pp: () => _translate(this.intl, 'biobeat/vitals/pp'),
    ppUnit: () => _translate(this.intl, 'biobeat/vitals/pp/unit'),
    spo2: () => _translate(this.intl, 'biobeat/vitals/spo2'),
    spo2Unit: () => _translate(this.intl, 'biobeat/vitals/spo2/unit'),
    sv: () => _translate(this.intl, 'biobeat/vitals/sv'),
    svUnit: () => _translate(this.intl, 'biobeat/vitals/sv/unit'),
    svr: () => _translate(this.intl, 'biobeat/vitals/svr'),
    svrUnit: () => _translate(this.intl, 'biobeat/vitals/svr/unit'),
    sweat: () => _translate(this.intl, 'biobeat/vitals/sweat'),
    sweatUnit: () => _translate(this.intl, 'biobeat/vitals/sweat/unit'),
    bodyTemp: () => _translate(this.intl, 'biobeat/vitals/bodyTemp'),
    bodyTempUnit: () => _translate(this.intl, 'biobeat/vitals/bodyTemp/unit'),
  };

  ble = {
    hr: () => _translate(this.intl, 'ble/hr'),
    hrUnit: () => _translate(this.intl, 'ble/hr-unit'),
    bpUnit: () => _translate(this.intl, 'ble/bp-unit'),
    bodyTemp: () => _translate(this.intl, 'ble/body-temp'),
    bodyTempUnit: () => _translate(this.intl, 'ble/body-temp-unit'),
    device: () => _translate(this.intl, 'ble/device'),
    diastolic: () => _translate(this.intl, 'ble/diastolic'),
    systolic: () => _translate(this.intl, 'ble/systolic'),
    reportDate: () => _translate(this.intl, 'ble/report-date'),
    reportTime: () => _translate(this.intl, 'ble/report-time'),
  };

  bluetooth = {
    connect: () => _translate(this.intl, 'bluetooth/connect'),
    connecting: () => _translate(this.intl, 'bluetooth/connecting'),
    connectionCancelled: () => _translate(this.intl, 'bluetooth/connection/cancelled'),
    connectionNoGATT: () => _translate(this.intl, 'bluetooth/connection/no-gatt'),
    connectionNoCharacteristic: (uuid: string) =>
      _translate(this.intl, 'bluetooth/connection/no-characteristic', { uuid }),
    deviceNotSupported: (manufacturerName: string, modelNumber: string) =>
      _translate(this.intl, 'bluetooth/device-not-supported', { manufacturerName, modelNumber }),
    disconnect: () => _translate(this.intl, 'bluetooth/disconnect'),
    reading: () => _translate(this.intl, 'bluetooth/reading'),
    notSupported: () => _translate(this.intl, 'bluetooth/not-supported'),
    selectType: () => _translate(this.intl, 'bluetooth/select-type'),
    selectTypeDescription: () => _translate(this.intl, 'bluetooth/select-type/description'),
  };

  bloodPressure = {
    systolic: () => _translate(this.intl, 'blood-pressure/systolic'),
    diastolic: () => _translate(this.intl, 'blood-pressure/diastolic'),
    meanArterialPressure: () => _translate(this.intl, 'blood-pressure/mean-arterial-pressure'),
    heartRate: () => this.vitals.heartRate(),
  };

  push = {
    alertNotificationInterval: () => _translate(this.intl, 'push/alert/interval'),
    enabled: () => _translate(this.intl, 'push/enabled'),
    intervalSeconds: (seconds: number) => this.time.durationSeconds(seconds),
    notifications: () => _translate(this.intl, 'push/notifications'),
    repeatedNotifications: () => _translate(this.intl, 'push/repeated-notifications'),
    sensorIncludeUnassigned: () => _translate(this.intl, 'push/sensor-include-unassigned'),
    sensorNotificationInterval: () => _translate(this.intl, 'push/sensor/interval'),
    sensorStatus: () => _translate(this.intl, 'push/sensor-status'),
  };

  buddycom = {
    buddycom: () => _translate(this.intl, 'buddycom'),
    enabled: () => _translate(this.intl, 'buddycom/enabled'),
    group: () => _translate(this.intl, 'buddycom/group'),
    language: () => _translate(this.intl, 'buddycom/language'),
    message: () => _translate(this.intl, 'buddycom/message'),
    sendMessage: () => _translate(this.intl, 'buddycom/send-message'),
    token: () => _translate(this.intl, 'buddycom/token'),
    typeMessage: () => _translate(this.intl, 'buddycom/type-message'),
    user: () => _translate(this.intl, 'buddycom/user'),
    statusMessageSent: () => _translate(this.intl, 'buddycom/status/message-sent'),
    errorAuth: () => _translate(this.intl, 'buddycom/error/auth-failed'),
    errorDenied: () => _translate(this.intl, 'buddycom/error/denied'),
    errorGroup: () => _translate(this.intl, 'buddycom/error/group-not-found'),
    errorParameter: () => _translate(this.intl, 'buddycom/error/invalid-parameter'),
    errorUnknown: () => _translate(this.intl, 'buddycom/error/unknown'),
    errorUser: () => _translate(this.intl, 'buddycom/error/user-not-found'),
  };

  bonx = {
    bonx: () => _translate(this.intl, 'bonx'),
    enabled: () => _translate(this.intl, 'bonx/enabled'),
    room: () => _translate(this.intl, 'bonx/room'),
    language: () => _translate(this.intl, 'bonx/language'),
    message: () => _translate(this.intl, 'bonx/message'),
    sendMessage: () => _translate(this.intl, 'bonx/send-message'),
    token: () => _translate(this.intl, 'bonx/token'),
    typeMessage: () => _translate(this.intl, 'buddycom/type-message'),
    statusMessageSent: () => _translate(this.intl, 'buddycom/status/message-sent'),
    errorInvalidTTS: () => _translate(this.intl, 'buddycom/error/invalid-tts'),
    errorInvalidToken: () => _translate(this.intl, 'buddycom/error/invalid-token'),
    errorDenied: () => _translate(this.intl, 'bonx/error/denied'),
    errorNotFound: () => _translate(this.intl, 'buddycom/error/resource-not-found'),
    errorParameter: () => _translate(this.intl, 'buddycom/error/invalid-parameter'),
    errorUnknown: () => _translate(this.intl, 'buddycom/error/unknown'),
  };

  careReport = {
    reportDate: () => _translate(this.intl, 'care-report/report-date'),
    careCenterName: () => _translate(this.intl, 'care-report/care-center-name'),
    residentName: () => _translate(this.intl, 'care-report/resident-name'),
    age: () => _translate(this.intl, 'care-report/age'),
    roomBed: () => _translate(this.intl, 'care-report/room-bed'),
    alertDate: () => _translate(this.intl, 'care-report/alert-date'),
    alertTime: () => _translate(this.intl, 'care-report/alert-time'),
    alertType: () => _translate(this.intl, 'care-report/alert-type'),
    details: () => _translate(this.intl, 'care-report/details'),
    duration: () => _translate(this.intl, 'care-report/duration'),
    resolution: () => _translate(this.intl, 'care-report/resolution'),
    resolver: () => _translate(this.intl, 'care-report/resolver'),
    residents: () => _translate(this.intl, 'care-report/residents'),
    selectResidents: () => _translate(this.intl, 'care-report/select-residents'),
    notes: () => _translate(this.intl, 'care-report/notes'),
    startDate: () => _translate(this.intl, 'care-report/start-date'),
    endDate: () => _translate(this.intl, 'care-report/end-date'),
    startTime: () => _translate(this.intl, 'care-report/start-time'),
    endTime: () => _translate(this.intl, 'care-report/end-time'),
    filterTitle: () => _translate(this.intl, 'care-report/filter-title'),
    nowDateFormat: () => _translate(this.intl, 'care-report/now-date-format'),
    nowDatetimeFormat: () => _translate(this.intl, 'care-report/now-datetime-format'),
    fileName: () => _translate(this.intl, 'care-report/file-name'),
    sheetName: () => _translate(this.intl, 'care-report/sheet-name'),
  };

  center = {
    address: () => _translate(this.intl, 'center/address'),
    alerts: () => _translate(this.intl, 'drawer/alerts'),
    alertSettings: () => _translate(this.intl, 'view/alert-settings'),
    careCenter: () => _translate(this.intl, 'center/care-center'),
    contact: () => _translate(this.intl, 'label/contact'),
    location: () => _translate(this.intl, 'center/location'),
    manager: () => _translate(this.intl, 'label/manager'),
    managedBy: (firstName: string, lastName: string) =>
      _translate(this.intl, 'center/managed-by', { firstName, lastName }),
    name: () => _translate(this.intl, 'center/name'),
    photo: () => _translate(this.intl, 'center/photo'),
    residents: () => _translate(this.intl, 'center/residents'),
    sensors: () => _translate(this.intl, 'drawer/sensors'),
    cameras: () => _translate(this.intl, 'view/cameras'),
    nurseCalls: () => _translate(this.intl, 'view/nurseCalls'),
    iosNotifications: () => _translate(this.intl, 'center/ios-notifications'),
    patientNotificationsHelp: () => _translate(this.intl, 'center/patient-notifications-help'),
    sensorNotificationsHelp: () => _translate(this.intl, 'center/sensor-notifications-help'),
    messengers: () => _translate(this.intl, 'center/messengers'),
  };

  charts = {
    pieChartTipResolution: () => _translate(this.intl, 'charts/pie-chart-tip-resolution'),
    duration: () => _translate(this.intl, 'charts/duration-mins'),
    xMinutes: (x: string) => {
      return _translate(this.intl, 'charts/x-mins', { x });
    },
  };

  dashboard = {
    layoutSettings: () => _translate(this.intl, 'dashboard/settings/layout'),
    soundSettings: () => _translate(this.intl, 'dashboard/settings/sounds'),
    sortSettings: () => _translate(this.intl, 'dashboard/settings/sort'),
    awakeSettings: () => _translate(this.intl, 'dashboard/settings/awake'),
    show: () => _translate(this.intl, 'label/show'),
    hide: () => _translate(this.intl, 'label/hide'),
    groupByStatus: () => _translate(this.intl, 'dashboard/group-by-status'),
    sortByRoom: () => _translate(this.intl, 'dashboard/sort-by-room'),
    settings: () => _translate(this.intl, 'dashboard/settings'),
    settingsTitle: () => _translate(this.intl, 'dashboard/settings/title'),
    alerts: () => _translate(this.intl, 'dashboard/alerts'),
    noData: () => _translate(this.intl, 'dashboard/no-data'),
    ok: () => _translate(this.intl, 'dashboard/ok'),
    warnings: () => _translate(this.intl, 'dashboard/warnings'),
    awake: () => _translate(this.intl, 'dashboard/awake'),

    alertSoundNotifications: () => _translate(this.intl, 'dashboard/alert/sound-notifications'),
    awakeSoundNotifications: () => _translate(this.intl, 'dashboard/awake/sound-notifications'),
  };

  drawer = {
    alerts: () => _translate(this.intl, 'drawer/alerts'),
    alertsSettings: () => _translate(this.intl, 'drawer/alert-settings'),
    alertsSettingsEdit: () => _translate(this.intl, 'drawer/alert-settings/edit'),
    center: () => _translate(this.intl, 'drawer/center'),
    locations: () => _translate(this.intl, 'drawer/locations'),
    residents: () => _translate(this.intl, 'drawer/residents'),
    sensors: () => _translate(this.intl, 'drawer/sensors'),
    settings: () => _translate(this.intl, 'drawer/settings'),
  };

  earlysense = {
    name: () => _translate(this.intl, 'earlysense/name'),
    earlysense: () => _translate(this.intl, 'earlysense/name'),
    live: () => _translate(this.intl, 'earlysense/live'),
    noomi: () => _translate(this.intl, 'earlysense/noomi'),
    email: () => _translate(this.intl, 'earlysense/email'),
    regStatus: (email: string, regStatus: string) =>
      _translate(this.intl, 'earlysense/reg-status/' + regStatus, { email }),
    regStatusRequested: (email: string) =>
      _translate(this.intl, 'earlysense/reg-status/requested', { email }),
    regStatusRegistered: (email: string) =>
      _translate(this.intl, 'earlysense/reg-status/registered', { email }),
    noomiRegistered: (userId: string) =>
      _translate(this.intl, 'earlysense/noomi/reg-status/registered', { userId }),
    noomiNotRegistered: () => _translate(this.intl, 'earlysense/noomi/reg-status/not-registered'),
  };

  noomi = {
    noomi: () => _translate(this.intl, 'earlysense/noomi'),
    deviceConfig: () => _translate(this.intl, 'earlysense/noomi/device-config'),
    responseStatus: (key: string) => _translate(this.intl, key ? key : 'noomi/status/unknown'),
  };

  aisleep = {
    aisleep: () => _translate(this.intl, 'aisleep/aisleep'),
    deviceConfig: () => _translate(this.intl, 'aisleep/device-config'),
    serverConfig: () => _translate(this.intl, 'aisleep/server-config'),
    useAirMatt: () => _translate(this.intl, 'aisleep/use-air-matt'),
    useMotionAlerts: () => _translate(this.intl, 'aisleep/use-motion-alerts'),
  };

  sensingWave = {
    sensingWave: () => _translate(this.intl, 'sensing-wave/sensing-wave'),
    sensingWave2: () => _translate(this.intl, 'sensing-wave/sensing-wave2'),
    deviceConfig: () => _translate(this.intl, 'sensing-wave/device-config'),
    sleepStage: () => _translate(this.intl, 'sensing-wave/sleep-stage'),
    position: () => _translate(this.intl, 'sensing-wave/position'),
    nrem3: () => _translate(this.intl, 'sensing-wave/sleep-stage/nrem3'),
    nrem2: () => _translate(this.intl, 'sensing-wave/sleep-stage/nrem2'),
    nrem1: () => _translate(this.intl, 'sensing-wave/sleep-stage/nrem1'),
    nrem: () => _translate(this.intl, 'sensing-wave/sleep-stage/nrem'),
    awake: () => _translate(this.intl, 'sensing-wave/sleep-stage/awake'),
    positionUp: () => _translate(this.intl, 'sensing-wave/position/up'),
    positionDown: () => _translate(this.intl, 'sensing-wave/position/down'),
    positionRight: () => _translate(this.intl, 'sensing-wave/position/right'),
    positionLeft: () => _translate(this.intl, 'sensing-wave/position/left'),
  };

  error = {
    report: () => _translate(this.intl, 'error/report'),
    save: () => _translate(this.intl, 'error/save'),
    unauthorized: () => _translate(this.intl, 'error/unauthorized'),
    pageNotFound: () => _translate(this.intl, 'error/404'),
    error: (e: unknown) => {
      if (isFirestoreError(e)) {
        return _translate(this.intl, 'error/firestore/' + e.code);
      } else if (isACError(e)) {
        return _translate(this.intl, e.code, e.translationParams);
      }
      return _translate(this.intl, 'error/unknown');
    },
  };

  geocoder = {
    noResults: () => _translate(this.intl, 'geocoder/no-results'),
  };

  label = {
    error: () => _translate(this.intl, 'label/error'),
    alerts: () => _translate(this.intl, 'label/alerts'),
    alertsEnabled: (enabled: boolean) =>
      _translate(this.intl, enabled ? 'label/alerts-enabled' : 'label/alerts-disabled'),
    alertsPaused: () => _translate(this.intl, 'label/alerts-paused'),
    date: () => _translate(this.intl, 'label/date'),
    camera: () => _translate(this.intl, 'label/camera'),
    category: () => _translate(this.intl, 'label/category'),
    cancelAlertNoActionRequired: () =>
      _translate(this.intl, 'label/cancel-alert-no-action-required'),
    comma: () => _translate(this.intl, 'label/comma'),
    remindMeLater: (minutes: number) => _translate(this.intl, 'label/remind-me-later', { minutes }),
    snooze: () => _translate(this.intl, 'label/snooze'),
    show: () => _translate(this.intl, 'label/show'),
    hide: () => _translate(this.intl, 'label/hide'),
    all: () => _translate(this.intl, 'label/all'),
    awake: () => _translate(this.intl, 'label/awake'),
    awakeWarnings: (on: boolean) =>
      _translate(this.intl, on ? 'label/awake-warnings' : 'label/awake-warnings-off'),
    beds: () => _translate(this.intl, 'label/beds'),
    bed: () => _translate(this.intl, 'label/bed'),
    bloodPressure: () => _translate(this.intl, 'label/blood-pressure'),
    temperature: () => _translate(this.intl, 'label/temperature'),
    celcius: () => _translate(this.intl, 'label/celcius'),
    status: () => _translate(this.intl, 'label/status'),
    farenheight: () => _translate(this.intl, 'label/farenheight'),
    description: () => _translate(this.intl, 'label/description'),
    deviceState: () => _translate(this.intl, 'label/device-state'),
    dialogLatest: () => _translate(this.intl, 'label/dialog/latest'),
    dialogDuration: () => _translate(this.intl, 'label/dialog/duration'),
    firstName: () => _translate(this.intl, 'person/first-name'),
    floor: () => _translate(this.intl, 'label/floor'),
    menu: () => _translate(this.intl, 'label/menu'),
    nurse: () => _translate(this.intl, 'label/nurse'),
    nurseCall: () => _translate(this.intl, 'label/nurse-call'),
    chargingStatus: () => _translate(this.intl, 'label/chargingStatus'),
    batteryLevel: () => _translate(this.intl, 'label/batteryLevel'),
    userId: () => _translate(this.intl, 'label/user-id'),
    kg: () => _translate(this.intl, 'label/kg'),
    lastName: () => _translate(this.intl, 'person/last-name'),
    loading: () => _translate(this.intl, 'label/loading'),
    manager: () => _translate(this.intl, 'label/manager'),
    hoursMinutes: () => _translate(this.intl, 'label/hours-minutes'),
    minutes: () => _translate(this.intl, 'label/minutes'),
    seconds: () => _translate(this.intl, 'label/seconds'),
    percent: () => _translate(this.intl, 'label/percent'),
    mobile: () => _translate(this.intl, 'label/mobile'),
    notes: () => _translate(this.intl, 'label/notes'),
    offline: () => _translate(this.intl, 'label/offline'),
    phone: () => _translate(this.intl, 'label/phone'),
    report: () => _translate(this.intl, 'label/report'),
    reports: () => _translate(this.intl, 'label/reports'),
    resolution: () => _translate(this.intl, 'label/resolution'),
    rooms: () => _translate(this.intl, 'label/rooms'),
    room: () => _translate(this.intl, 'label/room'),
    soundNotificationsOn: () => _translate(this.intl, 'label/sounds-notifications/on'),
    soundNotificationsOff: () => _translate(this.intl, 'label/sound-notificationss/off'),
    saving: () => _translate(this.intl, 'label/saving'),
    sensor: () => _translate(this.intl, 'label/sensor'),
    sensorEdit: () => _translate(this.intl, 'label/sensor-edit'),
    sms: () => _translate(this.intl, 'label/sms'),
    sort: () => _translate(this.intl, 'label/sort'),
    sortingByCategory: () => _translate(this.intl, 'label/sorting-by-status'),
    sortingByRoom: () => _translate(this.intl, 'label/sorting-by-room'),
    vitalsVisible: (visible: boolean) =>
      _translate(this.intl, visible ? 'label/vitals-visible' : 'label/vitals-not-visible'),
    vitalMeasurementsVisible: (visible: boolean) =>
      _translate(
        this.intl,
        visible ? 'label/vital-measurements-visible' : 'label/vital-measurements-not-visible'
      ),
    timeZone: () => _translate(this.intl, 'label/time-zone'),
    table: () => _translate(this.intl, 'label/table'),
    chart: () => _translate(this.intl, 'label/chart'),
    latestVersion: () => _translate(this.intl, 'label/latest-version'),
    updating: () => _translate(this.intl, 'label/updating'),
    update: () => _translate(this.intl, 'label/update'),
    help: () => _translate(this.intl, 'label/help'),
    updated: () => _translate(this.intl, 'label/updated'),
    vitals: () => _translate(this.intl, 'label/vitals'),
    recordVitals: () => _translate(this.intl, 'label/record-vitals'),
    print: () => _translate(this.intl, 'label/print'),
    printReport: () => _translate(this.intl, 'label/print-report'),
    itemXofY: (x: number, y: number) => {
      return _translate(this.intl, 'label/item-x-of-y', { x, y });
    },
    hardware: () => _translate(this.intl, 'label/hardware'),
    hardwareModel: () => _translate(this.intl, 'label/hardware-model'),
    softwareVersion: () => _translate(this.intl, 'label/software-version'),
    macAddress: () => _translate(this.intl, 'label/mac-address'),
    ipv4: () => _translate(this.intl, 'label/ipv4'),
    ipv6: () => _translate(this.intl, 'label/ipv6'),
    accessPointMac: () => _translate(this.intl, 'label/access-point-mac'),
    disconnectReason: () => _translate(this.intl, 'label/disconnect-reason'),
    connectedSince: () => _translate(this.intl, 'label/connected-since'),
    uptime: () => _translate(this.intl, 'label/uptime'),
    freeDiskSpace: () => _translate(this.intl, 'label/free-disk-space'),
    freeRam: () => _translate(this.intl, 'label/free-ram'),
    signalStrength: () => _translate(this.intl, 'label/signal-strength'),
    cpuUtilization: () => _translate(this.intl, 'label/cpu-utilization'),
    networkType: () => _translate(this.intl, 'label/network-type'),
    port: () => _translate(this.intl, 'label/port'),
    serialNumber: () => _translate(this.intl, 'label/serial-number'),
    runTime: () => _translate(this.intl, 'label/run-time'),
    remainingTime: () => _translate(this.intl, 'label/remaining-time'),
    version: () => _translate(this.intl, 'label/version'),
    weight: () => _translate(this.intl, 'label/weight'),
    chair: () => _translate(this.intl, 'label/chair'),
    notConnected: () => _translate(this.intl, 'label/not-connected'),
    today: () => _translate(this.intl, 'label/today'),
  };

  location = {
    list: () => _translate(this.intl, 'locations/list'),
    map: () => _translate(this.intl, 'locations/map'),
  };

  language = {
    language: () => _translate(this.intl, 'language'),
    english: () => _translate(this.intl, 'language/en'),
    hebrew: () => _translate(this.intl, 'language/he'),
    japanese: () => _translate(this.intl, 'language/ja'),
    portuguese: () => _translate(this.intl, 'language/pt'),
    zh_hans: () => _translate(this.intl, 'language/zh-hans'),
    zh_hant: () => _translate(this.intl, 'language/zh-hant'),
  };

  login = {
    domain: () => _translate(this.intl, 'login/domain'),
    domainNotFound: () => _translate(this.intl, 'login/domain-not-found'),
    domainNotImplemented: () => _translate(this.intl, 'login/domain-not-implemented'),
    email: () => _translate(this.intl, 'login/email'),
    forgotPassword: () => _translate(this.intl, 'login/forgot-password'),
    passwordReset: () => _translate(this.intl, 'login/passwordReset'),
    sendPasswordResetEmail: () => _translate(this.intl, 'login/sendPasswordResetEmail'),
    passwordResetWarning: () => _translate(this.intl, 'login/password-reset-warning'),
    password: () => _translate(this.intl, 'login/password'),
    selectUser: () => _translate(this.intl, 'login/select-user'),
    signIn: () => _translate(this.intl, 'login/sign-in'),
    signOut: () => _translate(this.intl, 'login/sign-out'),
    passwordResetEmailed: () => _translate(this.intl, 'login/passwordResetEmailed'),
    url: () => _translate(this.intl, 'login/url'),
    username: () => _translate(this.intl, 'login/username'),
  };

  person = {
    birthday: () => _translate(this.intl, 'person/birthday'),
    name: () => _translate(this.intl, 'person/name'),
    firstName: () => _translate(this.intl, 'person/first-name'),
    lastName: () => _translate(this.intl, 'person/last-name'),
    fullName: (first?: string | null, last?: string | null) => {
      if (!first) {
        first = '';
      }
      if (!last) {
        last = '';
      }
      return _translate(this.intl, 'person/full-name', { first, last });
    },
    age: () => _translate(this.intl, 'person/age'),
    gender: () => _translate(this.intl, 'person/gender'),
    genderFromType: (type?: models.GenderType | null) => {
      if (!type) {
        return '';
      } else {
        switch (type) {
          case models.GenderType.MALE:
            return this.person.genderMale();
          case models.GenderType.FEMALE:
            return this.person.genderFemale();
          case models.GenderType.OTHER:
          default:
            return this.person.genderOther();
        }
      }
    },
    genderMale: () => _translate(this.intl, 'person/gender-male'),
    genderFemale: () => _translate(this.intl, 'person/gender-female'),
    genderOther: () => _translate(this.intl, 'person/gender-other'),
  };

  report = {
    clickToZoom: () => _translate(this.intl, 'report/click-to-zoom'),
    days: () => _translate(this.intl, 'report/days'),
    duration: () => _translate(this.intl, 'report/duration'),
    careGivers: () => _translate(this.intl, 'report/care-givers'),
    noResults: () => _translate(this.intl, 'report/no-results'),
    numberOfDays: (x: number) =>
      x === 1
        ? _translate(this.intl, 'report/one-day')
        : _translate(this.intl, 'report/number-of-days', { x }),
    pinchToZoom: () => _translate(this.intl, 'report/pinch-to-zoom'),
    reports: () => _translate(this.intl, 'report/reports'),
    settings: () => _translate(this.intl, 'report/settings'),
    summary: () => _translate(this.intl, 'report/summary'),
    totalAlertsReported: () => _translate(this.intl, 'report/alerts/total-reported'),
    totalAlertsResolved: () => _translate(this.intl, 'report/alerts/total-resolved'),
    vitals: () => _translate(this.intl, 'report/vitals'),
    bloodPressureVitals: () => _translate(this.intl, 'report/blood-pressure-vitals'),
    sleep: () => _translate(this.intl, 'report/sleep'),
    nurse: () => _translate(this.intl, 'report/nurse'),
    alerts: () => _translate(this.intl, 'report/alerts'),
    vitalMeasurements: () => _translate(this.intl, 'report/vital-measurements'),
  };

  residentReport = {
    heartRateVitals: () => _translate(this.intl, 'resident-report/heart-rate-vitals'),
    respirationVitals: () => _translate(this.intl, 'resident-report/respiration-vitals'),
    occupancyVitals: () => _translate(this.intl, 'resident-report/occupancy-vitals'),
    movementVitals: () => _translate(this.intl, 'resident-report/movement-vitals'),
    sleepStageVitals: () => _translate(this.intl, 'resident-report/sleep-stage-vitals'),
    bloodPressureVitals: () => _translate(this.intl, 'resident-report/blood-pressure-vitals'),
    noActiveReports: () => _translate(this.intl, 'resident-report/no-active-reports'),
    weight: () => _translate(this.intl, 'label/weight'),
    weightUnit: () => _translate(this.intl, 'label/kg'),
    bodyTemp: () => _translate(this.intl, 'biobeat/vitals/bodyTemp'),
    bodyTempUnit: () => _translate(this.intl, 'biobeat/vitals/bodyTemp/unit'),
    bloodPressureUnit: () => _translate(this.intl, 'vitals/blood-pressure-unit'),

    earlySense: {
      sleepType: {
        outOfBed: () => _translate(this.intl, 'resident-report/early-sense/sleep-type/out-of-bed'),
        awake: () => _translate(this.intl, 'resident-report/early-sense/sleep-type/awake'),
        rem: () => _translate(this.intl, 'resident-report/early-sense/sleep-type/rem'),
        lightSleep: () =>
          _translate(this.intl, 'resident-report/early-sense/sleep-type/light-sleep'),
        deepSleep: () => _translate(this.intl, 'resident-report/early-sense/sleep-type/deep-sleep'),
      },
    },
  };

  careKarte = {
    label: () => _translate(this.intl, 'care-karte/label'),
    resident: () => _translate(this.intl, 'care-karte/resident'),
    bed: () => _translate(this.intl, 'care-karte/bed'),
    bedNone: () => _translate(this.intl, 'care-karte/bed-none'),
  };

  resident = {
    active: () => _translate(this.intl, 'resident/active'),
    bed: () => _translate(this.intl, 'resident/bed'),
    device: () => _translate(this.intl, 'resident/device'),
    deviceNone: () => _translate(this.intl, 'resident/device-none'),
    inActive: () => _translate(this.intl, 'resident/in-active'),
    location: () => _translate(this.intl, 'resident/location'),
    move: () => _translate(this.intl, 'resident/move'),
    name: () => _translate(this.intl, 'resident/name'),
    none: () => _translate(this.intl, 'resident/none'),
    noResidents: () => _translate(this.intl, 'resident/no-residents'),
    resident: () => _translate(this.intl, 'resident/resident'),
    edit: () => _translate(this.intl, 'resident/edit'),
    room: () => _translate(this.intl, 'resident/room'),
    selectSensorType: () => _translate(this.intl, 'resident/select-sensor-type'),
    sensor: () => _translate(this.intl, 'resident/sensor'),
    sensors: () => _translate(this.intl, 'resident/sensors'),
    vitalsReport: () => _translate(this.intl, 'resident/vitals-report'),
    configureSensor: () => _translate(this.intl, 'resident/configure-sensor'),
    highPriorityOn: () => _translate(this.intl, 'resident/high-priority-on'),
    highPriorityOff: () => _translate(this.intl, 'resident/high-priority-off'),
    highPriority: (on: boolean) =>
      _translate(this.intl, on ? 'resident/high-priority-on' : 'resident/high-priority-off'),
    display: () => _translate(this.intl, 'resident/display'),
  };

  role = {
    superuser: () => _translate(this.intl, 'role/superuser'),
    admin: () => _translate(this.intl, 'role/admin'),
    readOnly: () => _translate(this.intl, 'role/read-only'),
    careGiver: () => _translate(this.intl, 'role/care-giver'),
    developer: () => _translate(this.intl, 'role/developer'),
    director: () => _translate(this.intl, 'role/director'),
    hq: () => _translate(this.intl, 'role/hq'),
    manager: () => _translate(this.intl, 'role/manager'),
    name: () => _translate(this.intl, 'role/name'),
    regionalManager: () => _translate(this.intl, 'role/regional-manager'),
    fromType: (type?: models.UserRole | null) => {
      let role = this.role.careGiver();
      if (type) {
        switch (type) {
          case models.UserRole.superuser:
            role = this.role.superuser();
            break;
          case models.UserRole.admin:
            role = this.role.admin();
            break;
          case models.UserRole.hq:
            role = this.role.hq();
            break;
          case models.UserRole.manager:
            role = this.role.manager();
            break;
          case models.UserRole.care_giver:
            role = this.role.careGiver();
            break;
          case models.UserRole.read_only:
            role = this.role.readOnly();
            break;
          case models.UserRole.dev:
            role = this.role.developer();
            break;
          default:
            role = type;
            break;
        }
      }
      return role;
    },
  };

  camera = {
    noCameras: () => _translate(this.intl, 'camera/no-cameras'),
    noData: () => _translate(this.intl, 'camera/no-data'),
    noImage: () => _translate(this.intl, 'camera/no-image'),
    camera: () => _translate(this.intl, 'label/camera'),
    name: () => _translate(this.intl, 'camera/name'),
    cameraId: () => _translate(this.intl, 'camera/camera-id'),
    location: () => _translate(this.intl, 'camera/location'),
    type: () => _translate(this.intl, 'camera/type'),
    info: () => _translate(this.intl, 'camera/info'),
    status: () => _translate(this.intl, 'camera/status'),
    add: () => _translate(this.intl, 'camera/add'),
    edit: () => _translate(this.intl, 'camera/edit'),
    delete: () => _translate(this.intl, 'camera/delete'),
    blur: () => _translate(this.intl, 'camera/blur'),
    mute: () => _translate(this.intl, 'camera/mute'),
    deleteConfirm: () => _translate(this.intl, 'camera/delete/confirm'),
    configuration: () => _translate(this.intl, 'camera/configuration'),
  };

  nurseCall = {
    batteryLevel0: () => _translate(this.intl, 'nurse-call/batteryLevel0'),
    batteryLevel10: () => _translate(this.intl, 'nurse-call/batteryLevel10'),
    batteryLevel30: () => _translate(this.intl, 'nurse-call/batteryLevel30'),
    batteryLevel60: () => _translate(this.intl, 'nurse-call/batteryLevel60'),
    batteryLevel100: () => _translate(this.intl, 'nurse-call/batteryLevel100'),
    notCharged: () => _translate(this.intl, 'nurse-call/notCharged'),
    charging: () => _translate(this.intl, 'nurse-call/charging'),
    fullyCharged: () => _translate(this.intl, 'nurse-call/fullyCharged'),
    noNurseCalls: () => _translate(this.intl, 'nurse-call/no-nurseCalls'),
    nurseCall: () => _translate(this.intl, 'label/nurse-call'),
    imei: () => _translate(this.intl, 'nurse-call/imei'),
    type: () => _translate(this.intl, 'nurse-call/type'),
    typeBestReha: () => _translate(this.intl, 'nurse-call/type/bestReha'),
    add: () => _translate(this.intl, 'nurse-call/add'),
    addType: () => _translate(this.intl, 'nurse-call/add-type'),
    edit: () => _translate(this.intl, 'nurse-call/edit'),
    delete: () => _translate(this.intl, 'nurse-call/delete'),
    deleteConfirm: () => _translate(this.intl, 'nurse-call/delete/confirm'),
    configuration: () => _translate(this.intl, 'nurse-call/configuration'),
  };

  sensor = {
    noData: () => _translate(this.intl, 'sensor/no-data'),
    sensor: () => _translate(this.intl, 'sensors/sensor'),
    name: () => _translate(this.intl, 'sensor/name'),
    device: () => _translate(this.intl, 'sensor/device'),
    serialNumber: () => _translate(this.intl, 'sensor/serial-number'),
    location: () => _translate(this.intl, 'sensor/location'),
    detach: () => _translate(this.intl, 'sensor/detach'),
    attach: () => _translate(this.intl, 'sensor/attach'),
    type: () => _translate(this.intl, 'sensor/type'),
    info: () => _translate(this.intl, 'sensor/info'),
    status: () => _translate(this.intl, 'sensor/status'),
    version: () => _translate(this.intl, 'sensor/version'),
    add: () => _translate(this.intl, 'sensor/add'),
    addType: () => _translate(this.intl, 'sensor/add-type'),
    delete: () => _translate(this.intl, 'sensor/delete'),
    deleteConfirm: () => _translate(this.intl, 'sensor/delete/confirm'),
    configure: () => _translate(this.intl, 'sensor/configure'),
    configuration: () => _translate(this.intl, 'sensor/configuration'),
  };

  riskLevels = {
    off: () => _translate(this.intl, 'risk-levels/off'),
    high: () => _translate(this.intl, 'risk-levels/high'),
    veryHigh: () => _translate(this.intl, 'risk-levels/very-high'),
    medium: () => _translate(this.intl, 'risk-levels/medium'),
    low: () => _translate(this.intl, 'risk-levels/low'),
    veryLow: () => _translate(this.intl, 'risk-levels/very-low'),
    fallVeryLow: () => _translate(this.intl, 'risk-levels/fall-very-low'),
    fallVeryHigh: () => _translate(this.intl, 'risk-levels/fall-very-high'),
    fallCustom: () => _translate(this.intl, 'risk-levels/fall-custom'),
    turnoverLow: () => _translate(this.intl, 'risk-levels/turnover-low'),
    turnoverMedium: () => _translate(this.intl, 'risk-levels/turnover-medium'),
    turnoverCustom: () => _translate(this.intl, 'risk-levels/turnover-custom'),
    heartCustom: () => _translate(this.intl, 'risk-levels/heart-custom'),
    breathingCustom: () => _translate(this.intl, 'risk-levels/breathing-custom'),
  };

  sensors = {
    sensor: () => _translate(this.intl, 'sensors/sensor'),
    device: () => _translate(this.intl, 'sensor/device'),
    resident: () => _translate(this.intl, 'sensors/resident'),
    settings: () => _translate(this.intl, 'sensors/settings'),
    noSensors: () => _translate(this.intl, 'sensors/no-sensors'),
    updateFailed: () => _translate(this.intl, 'sensors/update-failed'),
    missingCustomField: () => _translate(this.intl, 'sensors/missing-custom'),
  };

  serviceWorker = {
    newVersion: () => _translate(this.intl, 'serviceWorker/new-version'),
  };

  simulate = {
    alerts: () => _translate(this.intl, 'simulate/alerts'),
  };

  settings = {
    notificationSettings: () => _translate(this.intl, 'settings/notification-settings'),
    dashboardSettings: () => _translate(this.intl, 'settings/dashboard-settings'),
    displaySettings: () => _translate(this.intl, 'settings/display-settings'),
    noticeSounds: () => _translate(this.intl, 'settings/notice-sounds'),
    sortDashboard: () => _translate(this.intl, 'settings/sort-dashboard'),
    byRoom: () => _translate(this.intl, 'settings/by-room'),
    byStatus: () => _translate(this.intl, 'settings/by-status'),
    displayVitals: () => _translate(this.intl, 'settings/display-vitals'),
    noticeAwake: () => _translate(this.intl, 'settings/notice-awake'),
    awakeSounds: () => _translate(this.intl, 'settings/notice-awake-sounds'),
    darkMode: () => _translate(this.intl, 'settings/dark-mode'),
    dark: () => _translate(this.intl, 'settings/dark-mode/dark'),
    light: () => _translate(this.intl, 'settings/dark-mode/light'),
    fontSize: () => _translate(this.intl, 'settings/font-size'),
    fontSizeLarge: () => _translate(this.intl, 'settings/font-size/large'),
    fontSizeMedium: () => _translate(this.intl, 'settings/font-size/medium'),
    fontSizeSmall: () => _translate(this.intl, 'settings/font-size/small'),
    revertToDefault: () => _translate(this.intl, 'settings/revert-to-default'),
  };

  theme = {
    small: () => _translate(this.intl, 'theme/font-size/small'),
    medium: () => _translate(this.intl, 'theme/font-size/medium'),
    large: () => _translate(this.intl, 'theme/font-size/large'),
    fontSize: () => _translate(this.intl, 'theme/font-size'),
    color: () => _translate(this.intl, 'theme/color'),
    dark: () => _translate(this.intl, 'theme/color/dark'),
    light: () => _translate(this.intl, 'theme/color/light'),
    dashboard: () => _translate(this.intl, 'theme/dashboard'),
    dashboardDefault: () => _translate(this.intl, 'theme/dashboard/default'),
    dashboardIcons: () => _translate(this.intl, 'theme/dashboard/icons'),
  };

  time = {
    date: (d: Date) => {
      return _translate(this.intl, 'time/date', {
        year: d.getFullYear(),
        month: d.getMonth() + 1,
        day: d.getDate(),
      });
    },
    dateTimestamp: (d: Date, pad = true) => {
      const year = d.getFullYear();
      const mo = d.getMonth() + 1;
      const month = mo < 10 && pad ? '0' + mo : mo;
      const da = d.getDate();
      const day = da < 10 && pad ? '0' + da : da;
      return year + '-' + month + '-' + day;
    },
    time: (d: Date, pad = true, plain = true) => {
      const minutes = d.getMinutes();
      const hours = d.getHours();
      return this.time.hoursMinutes(hours, minutes, pad, plain);
    },
    hoursMinutes: (hours: number, minutes: number, pad = true, plain = true) => {
      return _translate(this.intl, plain ? 'time/time-plain' : 'time/time', {
        hours: hours < 10 && pad ? '0' + hours : hours,
        minutes: minutes < 10 ? '0' + minutes : minutes,
      });
    },
    hoursMinutesAMPM: (hours: number, minutes: number, plain = true) => {
      const ampm = _translate(this.intl, hours >= 12 ? 'time/pm' : 'time/am');
      return (
        _translate(this.intl, plain ? 'time/time-plain' : 'time/time', {
          hours: hours % 12 === 0 ? '12' : hours % 12,
          minutes: minutes < 10 ? '0' + minutes : minutes,
        }) +
        ' ' +
        ampm
      );
    },
    duration: (days: number, hours: number, minutes: number, seconds: number) => {
      if (days > 0) {
        return _translate(this.intl, 'time/durationHours', {
          hours: days * 24 + hours,
          minutes: minutes < 10 ? ' ' + minutes : minutes,
          seconds: seconds < 10 ? ' ' + seconds : seconds,
        });
      } else if (hours > 0) {
        return _translate(this.intl, 'time/durationHours', {
          hours: hours < 10 ? ' ' + hours : hours,
          minutes: minutes < 10 ? ' ' + minutes : minutes,
          seconds: seconds < 10 ? ' ' + seconds : seconds,
        });
      } else if (minutes > 0) {
        return _translate(this.intl, 'time/durationMinutes', {
          minutes: minutes < 10 ? ' ' + minutes : minutes,
          seconds: seconds < 10 ? ' ' + seconds : seconds,
        });
      } else if (seconds > 0) {
        return _translate(this.intl, 'time/durationMinutes', {
          minutes: ' 0',
          seconds: seconds < 10 ? ' ' + seconds : seconds,
        });
      } else {
        return '----';
      }
    },
    timeElapsed: (days: number, hours: number, minutes: number) => {
      // "time/time-days-hours": "{days}d {hours}h",
      // "time/time-hours": "{hours}h",
      // "time/time-hours-minutes": "{hours}m {minutes}m",
      // "time/time-minutes": "{hours}m",
      return _translate(
        this.intl,
        days > 0
          ? 'time/time-days-hours'
          : hours > 0
          ? 'time/time-hours-minutes'
          : 'time/time-minutes',
        {
          days,
          hours,
          minutes,
        }
      );
    },
    durationSeconds: (seconds: number) => {
      return _translate(this.intl, 'time/durationSeconds', {
        seconds,
      });
    },
  };

  user = {
    changeUser: () => _translate(this.intl, 'user/change-user'),
    profile: () => _translate(this.intl, 'user/profile'),
    edit: () => _translate(this.intl, 'user/edit'),
    myProfile: () => _translate(this.intl, 'user/my-profile'),
    mySettings: () => _translate(this.intl, 'user/my-settings'),
    settings: () => _translate(this.intl, 'user/settings'),
    person: () => _translate(this.intl, 'user/person'),
    affiliation: () => _translate(this.intl, 'user/affiliation'),
    photo: () => _translate(this.intl, 'user/photo'),
    changePassword: () => _translate(this.intl, 'user/change-password'),
    newPassword: () => _translate(this.intl, 'user/new-password'),
    reenterNewPassword: () => _translate(this.intl, 'user/reenter-new-password'),
  };

  validation = {
    connectionCouldNotBeTested: (error: string) =>
      _translate(this.intl, 'validation/connection-could-not-be-tested', { error }),
    invalidAge: () => _translate(this.intl, 'validation/invalid-age'),
    invalidBirthday: () => _translate(this.intl, 'validation/invalid-birthday'),
    invalidSerialNumber: () => _translate(this.intl, 'validation/invalid-serial-number'),
    invalid: () => _translate(this.intl, 'validation/invalid'),
    invalidCredentials: () => _translate(this.intl, 'validation/invalid-credentials'),
    invalidDate: (days: number) =>
      _translate(this.intl, 'validation/invalid-date', { VALUE: days }),
    invalidDateFormat: (target: string) =>
      _translate(this.intl, 'validation/invalid-date-format', { target }),
    invalidDateOnConditionOrBeforeIt: (target: string, condition: string) =>
      _translate(this.intl, 'validation/invalid-date-on-conditon-or-before-it', {
        target,
        condition,
      }),
    invalidDateUpToConditionYearAgo: (target: string, condition: number) =>
      _translate(this.intl, 'validation/invalid-date-up-to-condition-year-ago', {
        target,
        condition,
      }),
    invalidEmail: () => _translate(this.intl, 'validation/invalid-email'),
    invalidFloor: () => _translate(this.intl, 'validation/invalid-floor'),
    invalidIMEI: () => _translate(this.intl, 'validation/invalid-imei'),
    invalidPhone: () => _translate(this.intl, 'validation/invalid-phone'),
    invalidURL: () => _translate(this.intl, 'validation/invalid-url'),
    requiredField: () => _translate(this.intl, 'validation/required-field'),
    verifyField: () => _translate(this.intl, 'validation/verify-field'),
    residentAlreadyExists: () => _translate(this.intl, 'validation/resident-exists'),
    roomAlreadyUsed: () => _translate(this.intl, 'validation/room-used'),
    biobeatIdAlreadyInUse: (id: string, name: string) =>
      _translate(this.intl, 'validation/biobeat/id-already-in-use', { id, name }),
    earlysenseIdAlreadyInUse: (id: string, name: string) =>
      _translate(this.intl, 'validation/earlysense/id-already-in-use', { id, name }),
    sensorIsAlreadyRegistered: () => _translate(this.intl, 'validation/id-already-registered'),
    cameraIdAlreadyRegistered: () => _translate(this.intl, 'validation/camera/already-registered'),
    nurseCallIdAlreadyRegistered: () =>
      _translate(this.intl, 'validation/narseCall/already-registered'),
  };

  view = {
    admin: () => _translate(this.intl, 'view/admin'),
    alerts: () => _translate(this.intl, 'view/alerts'),
    alertSettings: () => _translate(this.intl, 'view/alert-settings'),
    center: () => _translate(this.intl, 'view/center'),
    dashboard: () => _translate(this.intl, 'view/dashboard'),
    home: () => _translate(this.intl, 'view/home'),
    locations: () => _translate(this.intl, 'view/locations'),
    nursecalls: () => _translate(this.intl, 'view/nursecalls'),
    residents: () => _translate(this.intl, 'view/residents'),
    residentReport: () => _translate(this.intl, 'view/resident-report'),
    reports: () => _translate(this.intl, 'view/reports'),
    cameras: () => _translate(this.intl, 'view/cameras'),
    sensors: () => _translate(this.intl, 'view/sensors'),
    settings: () => _translate(this.intl, 'view/alertsettings'),
    vitals: () => _translate(this.intl, 'view/vitals'),
  };

  sleepStatus = {
    dozing: () => _translate(this.intl, 'sleep-status/dozing'),
    asleep: () => _translate(this.intl, 'sleep-status/asleep'),
    awake: () => _translate(this.intl, 'sleep-status/awake'),
    unavailable: () => _translate(this.intl, 'sleep-status/unavailable'),
  };

  vitals = {
    ahi: () => _translate(this.intl, 'vitals/ahi'),
    ahiRate: () => _translate(this.intl, 'vitals/ahi-rate'),
    ahiRateCompact: () => _translate(this.intl, 'vitals/ahi-rate-compact'),
    averageHeartRate: () => _translate(this.intl, 'vitals/heart-rate/average'),
    averageRespirationRate: () => _translate(this.intl, 'vitals/respiration/average'),
    durationDaysUnit: () => _translate(this.intl, 'vitals/duration/days-unit'),
    durationHoursUnit: () => _translate(this.intl, 'vitals/duration/hours-unit'),
    durationMinutesUnit: () => _translate(this.intl, 'vitals/duration/minutes-unit'),
    awake: () => _translate(this.intl, 'vitals/awake'),
    bloodPressureUnit: () => _translate(this.intl, 'vitals/blood-pressure-unit'),
    deep: () => _translate(this.intl, 'vitals/deep'),
    deepSleep: () => _translate(this.intl, 'vitals/deep-sleep'),
    deepSleepUnit: () => _translate(this.intl, 'vitals/deep-sleep/unit'),
    heartRate: () => _translate(this.intl, 'vitals/heart-rate'),
    heartRateUnit: () => _translate(this.intl, 'vitals/heart-rate-unit'),
    inBed: () => _translate(this.intl, 'vitals/in-bed'),
    inBedStatusUnavailable: () => _translate(this.intl, 'vitals/in-bed-status-unavailable'),
    light: () => _translate(this.intl, 'vitals/light'),
    lightSleep: () => _translate(this.intl, 'vitals/light-sleep'),
    lightSleepUnit: () => _translate(this.intl, 'vitals/light-sleep/unit'),
    movement: () => _translate(this.intl, 'vitals/movement'),
    movementUnit: () => _translate(this.intl, 'vitals/movement-unit'),
    moving: () => _translate(this.intl, 'vitals/moving'),
    noSensorData: () => _translate(this.intl, 'vitals/no-sensor-data'),
    numberOfOutOfBed: () => _translate(this.intl, 'vitals/number-of-out-of-bed'),
    numberOfOutOfBedUnit: () => _translate(this.intl, 'vitals/number-of-out-of-bed-unit'),
    occupancyUnit: () => _translate(this.intl, 'vitals/occupancy-unit'),
    outOfBed: () => _translate(this.intl, 'vitals/out-of-bed'),
    relaxation: () => _translate(this.intl, 'vitals/relaxation'),
    rem: () => _translate(this.intl, isVendorToppan() ? 'vitals/rem/toppan' : 'vitals/rem'),
    remSleep: () =>
      _translate(this.intl, isVendorToppan() ? 'vitals/rem-sleep/toppan' : 'vitals/rem-sleep'),
    remSleepUnit: () => _translate(this.intl, 'vitals/rem-sleep/unit'),
    respiration: () => _translate(this.intl, 'vitals/respiration'),
    respirationRateUnit: () => _translate(this.intl, 'vitals/respiration-rate-unit'),
    sitting: () => _translate(this.intl, 'vitals/sitting'),
    sleepScore: () => _translate(this.intl, 'vitals/sleep-score'),
    sleepScoreUnit: () => _translate(this.intl, 'vitals/sleep-score/unit'),
    sleepScoreUnitCompact: () => _translate(this.intl, 'vitals/sleep-score/unit-compact'),
    sleepStageUnit: () => _translate(this.intl, 'vitals/sleep-stage'),
    sleepTime: () => _translate(this.intl, 'vitals/sleep-time'),
    sleepTimeMinutes: () => _translate(this.intl, 'vitals/sleep-time/minutes'),
    sleepTimeMin: () => _translate(this.intl, 'vitals/sleep-time/min'),
    sleepTimeHoursMinutes: () => _translate(this.intl, 'vitals/sleep-time/hours-minutes'),
    status: () => _translate(this.intl, 'vitals/status'),
    stressLevel: () => _translate(this.intl, 'vitals/stress-level'),
    stressLevelHigh: () => _translate(this.intl, 'vitals/stress-level-high'),
    stressLevelLow: () => _translate(this.intl, 'vitals/stress-level-low'),
    stressLevelMed: () => _translate(this.intl, 'vitals/stress-level-med'),
    stressLevelUnit: () => _translate(this.intl, 'vitals/stress-level-unit'),
    summary: () => _translate(this.intl, 'vitals/summary'),
    unknown: () => _translate(this.intl, 'vitals/unknown'),
    wellbeing: () => _translate(this.intl, 'vitals/wellbeing'),
    wellbeingRelaxed: () => _translate(this.intl, 'vitals/wellbeing-relaxed'),
    wellbeingModerate: () => _translate(this.intl, 'vitals/wellbeing-moderate'),
    wellbeingStressed: () => _translate(this.intl, 'vitals/wellbeing-stressed'),
  };

  constructor(intl: IntlShape) {
    this.intl = intl;
  }

  translate(id: string | ACErrorCodesEnum, values?: MessageValues) {
    return _translate(this.intl, id, values);
  }

  translateException(e: unknown) {
    if (isACError(e)) {
      return this.translate(e.code, e.translationParams);
    } else if (isErrorMessage(e)) {
      return this.translate(e.code, e.messageParams);
    } else if (isError(e)) {
      return this.translate(e.message);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
      const m = (e as any).message;
      return this.translate(m ? m : ACErrorCodes.ErrorUnknown);
    }
  }
}

const strings = (intl: IntlShape) => {
  return new StringsIntl(intl);
};

export default strings;
