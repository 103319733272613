import { PushRegistryItem } from 'attentive-connect-api-fetch/dist';
import { CareCenter } from 'attentive-connect-store/dist/models';
import { Database } from 'attentive-connect-store/dist/services';
import OneSignal from 'react-onesignal';
import { Semaphore } from '../data/Semaphore';
import { Hybrid } from '../hybrid';
import { getLogger } from '../logger';
import * as app from '../redux';

const logger = getLogger('push', 'info');

const SUSPENDED = 'suspended';
const USE_ONESIGNAL_TAGS = false;
const USE_ONESIGNAL_BROWSER = false;
const USE_HEARTBEAT = true;

export type PushStatus = 'none' | 'active' | 'suspended' | 'heartbeat' | 'error';

// Tags are used to filter notifications.
// We use the following tags:
// - careCenterId: the id of the care center the user is currently viewing
interface Tags {
  careCenterId: string | null;
  heartbeat?: string;
}

interface TagsObject<T> {
  [key: string]: T;
}

export class Push {
  private static _instance: Push | undefined;
  private static _semaphore = new Semaphore(1, 'Push');
  private _db: Database | undefined;
  private _appId: string | undefined;
  private _userId: string | null | undefined;
  private _fcmUserId: string | null | undefined;
  private _pushToken: string | null | undefined;
  private _fcmToken: string | null | undefined;
  private _centers: string[];
  private _tags: Tags = { careCenterId: SUSPENDED };
  private _hybridIsSuspended = false;
  private _heartbeatInterval: NodeJS.Timeout | undefined;
  // private _tagsSuspended: TagsObject<string | null> = {};

  private constructor() {
    // singleton
    this._centers = [];
  }

  static async mount(): Promise<Push> {
    try {
      logger.debug('mounting push notifications...');
      await Push._semaphore.acquire();
      if (!Push._instance) {
        logger.debug('push notifications are now mounted');
        Push._instance = new Push();
      } else {
        logger.debug('push notifications are already mounted');
      }
      return Push._instance;
    } finally {
      Push._semaphore.release();
    }
  }

  static get instance() {
    return Push._instance;
  }

  get isPushConfigured() {
    return this.appId !== undefined && this.appId.length > 0 && this.db !== undefined;
  }

  get hybrid() {
    return Hybrid.instance();
  }

  get appId() {
    return this._appId;
  }

  get userId() {
    return this._userId;
  }

  get pushToken() {
    return this._pushToken;
  }

  get fcmUserId() {
    return this._fcmUserId;
  }

  get fcmToken() {
    return this._fcmToken;
  }

  async getUserId() {
    if (!this._userId && this.hybrid && this.hybrid.oneSignalPlugin) {
      try {
        logger.notice('user id is not set: get user id from OneSignal (retrying...)');
        const deviceState = await this.hybrid.oneSignalPlugin.getDeviceState();
        logger.debug(`oneSignalPlugin.getDeviceState() returned:`, deviceState);
        if (deviceState) {
          this._userId = deviceState.userId;
          this._pushToken = deviceState.pushToken;
        }
        if (!this._userId) {
          logger.error('could not get user id from OneSignal');
          this.setPushStatus('error');
        } else {
          logger.debug(`OneSignal user id is now: ${this._userId}`);
        }
      } catch (e) {
        logger.error(`could not get user id from OneSignal:`, e);
        this.setPushStatus('error');
      }
    }
    return this._userId;
  }

  async getPushToken() {
    if (!this._pushToken && this.hybrid && this.hybrid.oneSignalPlugin) {
      try {
        logger.notice('push token is not set: get push token from OneSignal (retrying...)');
        const deviceState = await this.hybrid.oneSignalPlugin.getDeviceState();
        logger.debug(`oneSignalPlugin.getDeviceState() returned:`, deviceState);
        if (deviceState) {
          this._userId = deviceState.userId;
          this._pushToken = deviceState.pushToken;
        }
        if (!this._pushToken) {
          logger.debug('could not get push token from OneSignal');
          this.setPushStatus('error');
        } else {
          logger.debug(`OneSignal push token is now: ${this._pushToken}`);
        }
      } catch (e) {
        logger.error(`could not get push token from OneSignal:`, e);
        this.setPushStatus('error');
      }
    }
    return this._pushToken;
  }

  async getFcmUserId() {
    if (!this._fcmUserId && this.hybrid && this.hybrid.fcmPlugin) {
      if (!this._fcmToken) {
        await this.getFcmToken();
      }
      if (this._fcmToken) {
        this._fcmUserId = 'fcm' + this._fcmToken;
      } else {
        this._fcmUserId = undefined;
      }
    }
    return this._fcmUserId;
  }

  async getFcmToken() {
    if (!this._fcmToken && this.hybrid && this.hybrid.fcmPlugin) {
      const fcmToken = await this.hybrid.fcmPlugin.getToken();
      if (fcmToken) {
        this._fcmToken = fcmToken;
      }
    }
    return this._fcmToken;
  }

  get centers() {
    return this._centers;
  }

  get db() {
    return this._db;
  }

  setPushStatus = (status: PushStatus) => {
    if (app.store) {
      app.store.dispatch(app.context.setPushStatus(status));
      logger.debug(`setPushStatus(): push status is now '${status}'`);
    } else {
      logger.error(`setPushStatus(): could not set push status '${status}'`);
    }
  };

  isHybridSuspended = () => {
    return this._hybridIsSuspended;
  };

  /**
   * Called when the app becomes suspended (e.g. when the user switches to another app)
   */
  onHybridSuspend = async () => {
    try {
      this.setPushStatus('suspended');
      await Push._semaphore.acquire();
      // stop the heartbeat
      if (this._heartbeatInterval && USE_HEARTBEAT) {
        clearInterval(this._heartbeatInterval);
        this._heartbeatInterval = undefined;
      }
      this._hybridIsSuspended = true;
      this.sendTags();
    } finally {
      Push._semaphore.release();
    }
  };

  /**
   * Called when the app becomes resumed (e.g. when the user switches back to the app)
   */
  onHybridResume = async () => {
    try {
      this.setPushStatus((this.userId || this.fcmUserId) ? 'active' : 'error');
      await Push._semaphore.acquire();
      this._hybridIsSuspended = false;
      logger.debug('resume push notifications');
      // stop the current heartbeat if it is running
      if (this._heartbeatInterval && USE_HEARTBEAT) {
        clearInterval(this._heartbeatInterval);
        this._heartbeatInterval = undefined;
      }
      // send current tags and start the heartbeat
      this.sendTags();
      if (USE_HEARTBEAT) {
        const heartbeatSec = this.db?.env.cache.pushHeartbeatSeconds || 10;
        this._heartbeatInterval = setInterval(() => {
          this.sendTags();
        }, heartbeatSec * 1000);
      }
      this.deleteTags();
    } finally {
      Push._semaphore.release();
    }
  };

  setAppId = async (appId: string | undefined, db: Database) => {
    if (appId !== undefined && appId.length > 0 && appId.indexOf('ONESIGNAL') < 0) {
      logger.info(`OneSignal app id: ${appId}`);
      this._appId = appId;
      if (this.hybrid) {
        this._db = db;

        if (this.hybrid.fcmPlugin) {
          await this.getFcmToken();
          await this.getFcmUserId();
          logger.info(`fcm user id: ${this._userId}`);
          logger.info(`fcm push token: ${this._pushToken}`);
          this.hybrid.fcmPlugin.requestPermission(true);
        }
        if (this.hybrid.oneSignalPlugin) {
          this.hybrid.oneSignalPlugin.setAppId(appId);
          await this.hybrid.oneSignalPlugin
            .promptForPushNotificationsWithUserResponse()
            .catch((e: Error) => {
              logger.error(`could not prompt for push notifications: ${e.message}`);
            });
          const deviceState = await this.hybrid.oneSignalPlugin.getDeviceState();
          logger.debug(`setAppId() -> oneSignalPlugin.getDeviceState() returned:`, deviceState);
          if (deviceState) {
            this._userId = deviceState.userId;
            this._pushToken = deviceState.pushToken;
          } else {
            this._userId = undefined;
            this._pushToken = undefined;
          }
          logger.info(`OneSignal user id: ${this._userId}`);
          logger.info(`OneSignal push token: ${this._pushToken}`);
        }
      } else if (USE_ONESIGNAL_BROWSER) {
        // browser
        const scope = process.env.PUBLIC_URL + '/push/onesignal/';
        const path = scope.startsWith('/') ? scope.substring(1) : scope;
        await OneSignal.init({
          appId,
          defaultTitle: 'AttentiveConnect',
          persistNotification: true,
          autoResubscribe: true,
          serviceWorkerParam: {
            scope: scope,
          },
          // NOTE: on OneSignal in the 'app' configuration the
          // NOTE: path had to be set as well => ac/push/onesignal/OneSignalSDKWorker.js
          // NOTE: no leading slash
          serviceWorkerPath: path + 'OneSignalSDKWorker.js',
          serviceWorkerUpdaterPath: path + 'OneSignalSDKUpdaterWorker.js',
          allowLocalhostAsSecureOrigin: true,
          // TODO - Safari support - should read from env, get this from onesignal custom config
          // safari_web_id: 'web.onesignal.auto.09206a8d-cae3-491c-ad76-7a8d47a79aca',
          // notifyButton: {
          //   enable: true,
          // },
          promptOptions: {
            customlink: {
              enabled: true /* Required to use the Custom Link */,
              style: 'link' /* Has value of 'button' or 'link' */,
              size: 'medium' /* One of 'small', 'medium', or 'large' */,
              color: {
                button: '#E12D30' /* Color of the button background if style = "button" */,
                text: '#FFFFFF' /* Color of the prompt's text */,
              },
              text: {
                subscribe:
                  'Subscribe to push notifications' /* Prompt's text when not subscribed */,
                unsubscribe:
                  'Unsubscribe from push notifications' /* Prompt's text when subscribed */,
                explanation:
                  'Get updates when alerts occur' /* Optional text appearing before the prompt button */,
              },
              unsubscribeEnabled:
                true /* Controls whether the prompt is visible after subscription */,
            },
          },
        }).catch((e: Error) => {
          logger.error(`could not initialize OneSignal: ${e.message}`);
          this._appId = undefined;
        });
        if (this._appId !== undefined) {
          await OneSignal.showSlidedownPrompt().catch((e: Error) => {
            logger.error(`could not show slidedown prompt: ${e.message}`);
          });
          await OneSignal.getUserId()
            .then((userId: string | null | undefined) => {
              this._userId = userId;
              logger.info(`OneSignal user id: ${userId}`);
            })
            .catch((e: Error) => {
              logger.error(`could not get user id: ${e.message}`);
              this._appId = undefined;
            });
        }
      }
      // reset everything when initializing
      // await this.deleteTags();
      // we start in a active state
      await this.onHybridResume();
    } else {
      logger.notice(`push notifications are disabled: OneSignal app id is not configured`);
      this._appId = undefined;
    }
  };

  sendTags = async () => {
    if (this.isPushConfigured) {
      if (USE_ONESIGNAL_TAGS) {
        const tags: Tags = {
          careCenterId: this._tags.careCenterId,
          heartbeat: Math.round(Date.now() / 1000).toString(),
        };
        this._tags = tags;
        if (this.hybrid) {
          if (this.hybrid.oneSignalPlugin) {
            this.hybrid.oneSignalPlugin.sendTags(tags as unknown as TagsObject<string | null>);
          }
        } else {
          await OneSignal.sendTags(tags);
        }
        this.debugTags('[send tags]', tags);
      } else {
        this.setPushStatus('heartbeat');
        const userId = await this.getUserId();
        const pushToken = await this.getPushToken();
        const fcmToken = await this.getFcmToken();
        const fcmUserId = await this.getFcmUserId();

        logger.debug('send fcmUserId: ', fcmUserId);
        logger.debug('send fcmToken: ', fcmToken);
        this.centers.forEach((center) => {
          logger.debug('send centers: ', center);
        });
        if (this.db && this.db.authUser?.uid && userId && pushToken) {
          const api = await this.db.api();
          const item: PushRegistryItem = {
            oneSignalUserId: userId,
            pushToken: pushToken,
            careCenterIds: this.centers,
            // AC-1296 - only use heartbeat while the app is active
            heartbeat: USE_HEARTBEAT && !this.isHybridSuspended(),
          };
          // we user the oneSignalUserId as the id
          // the pushToken is ephemeral and can change
          api.pushRegistry
            .pushRegistrySet({ id: userId, pushRegistryItem: item })
            .then(() => {
              this.setPushStatus('active');
              logger.debug(`set OneSignal id ${userId} in push registry`, item);
            })
            .catch((e: unknown) => {
              this.setPushStatus('error');
              logger.error(`could not set OneSignal id ${userId} in push registry`, { item, e });
            });
        } else if (this.db && !this.db.authUser) {
          await this.deleteTags();
          this.setPushStatus('none');
        } else {
          this.setPushStatus('error');
        }
        if (this.db && this.db.authUser?.uid && fcmUserId && fcmToken) {
          const api = await this.db.api();
          const item: PushRegistryItem = {
            fcmToken: fcmToken,
            careCenterIds: this.centers,
            heartbeat: USE_HEARTBEAT && true,
          };
          api.pushRegistry
            .pushRegistrySet({ id: fcmUserId, pushRegistryItem: item })
            .then(() => {
              this.setPushStatus('active');
              logger.debug(`set FCM id ${fcmUserId} in push registry`, item);
            })
            .catch((e: unknown) => {
              logger.error(`could not set FCM id ${fcmUserId} in push registry`, e);
            });
        } else if (this.db && !this.db.authUser) {
          await this.deleteTags();
          this.setPushStatus('none');
        } else {
          this.setPushStatus('error');
        }
      }
    }
  };

  filterCareCenters = async (centers: CareCenter[]) => {
    if (this.isPushConfigured) {
      const tags: Tags = {
        careCenterId: centers.length > 0 ? centers[0].id : SUSPENDED,
      };
      this._centers = centers.map((c) => c.id);
      logger.debug(`filter care centers: ${this.centers.join(', ')}`);
      this._tags = tags;
      this.debugTags('[filter care centers] update filter', tags);
      this.sendTags();
    }
  };

  deleteTags = async () => {
    if (this.isPushConfigured) {
      logger.debug('[delete current tags]');
      if (USE_ONESIGNAL_TAGS) {
        if (this.hybrid) {
          if (this.hybrid.oneSignalPlugin) {
            const tags = await this.hybrid.oneSignalPlugin.deleteTags();
            if (logger.isDebugEnabled()) {
              this.debugTags('[delete current tags] delete tag', tags as unknown as Tags);
            }
          }
        } else {
          let tags: Tags | undefined = undefined;
          await OneSignal.getTags((t: Tags) => {
            tags = t;
          });
          if (tags) {
            await OneSignal.deleteTags(Object.keys(tags));
            if (logger.isDebugEnabled() && tags !== undefined) {
              this.debugTags('[delete current tags] delete tag', tags);
            }
            // await OneSignal.getTags((t: TagsObject) => (tags = t));
          }
        }
      } else {
        this.setPushStatus('heartbeat');
        const userId = await this.getUserId();
        const pushToken = await this.getPushToken();
        const fcmToken = await this.getFcmToken();
        const fcmUserId = await this.getFcmUserId();
        logger.debug('deleted fcmToken: ', fcmToken);
        logger.debug('deleted fcmUserId: ', fcmUserId);
        if (this.db && userId && pushToken) {
          const api = await this.db.api();
          api.pushRegistry.pushRegistryRemove({ id: userId }).catch((e: Error) => {
            logger.debug(`could not remove ${pushToken} from push registry: ${e.message}`);
          });
          this.setPushStatus('active');
        } else {
          this.setPushStatus('error');
        }
        if (this.db && fcmUserId && fcmToken && this.hybrid?.fcmPlugin) {
          const api = await this.db.api();
          api.pushRegistry.pushRegistryRemove({ id: fcmUserId }).catch((e: Error) => {
            logger.debug(`could not remove ${fcmUserId} from push registry: ${e.message}`);
          });
        }
      }
      this._tags = { careCenterId: null };
      // this._tagsSuspended = {};
    }
  };

  debugTags = (prefix: string, tags: Tags) => {
    if (logger.isDebugEnabled()) {
      Object.keys(tags).forEach((tag) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
        const tagValue = (tags as any)[tag];
        logger.debug(`${prefix}: ${tag}=${tagValue}`);
      });
    }
  };
}
