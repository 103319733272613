import React from 'react';

import { isHybrid } from '../hybrid';
import { getLogger } from '../logger';
import * as Views from '../views';
import deployment from '../Deployment';

const logger = getLogger('routes', 'info');

export interface ACRouteMatcher {
  path: string;
  match: (path: string) => boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: React.ComponentType<any> | null;
}

const uri = (path: string) => {
  const _route = deployment.pathname + (path.startsWith('/') ? path.slice(1) : path);
  logger.debug(`uri(${path}): ${_route}`);
  return _route;
};

export const basepath = {
  // /admin is reserved for the admin app
  admin: '/_admin',
  about: '/about',
  alert: '/alerts/alert',
  alerts: '/alerts',
  alertSettingsList: '/alerts/settings',
  alertSettingsEdit: '/alerts/settings/edit',
  alertSettingsSensor: '/alerts/settings/sensor',
  camera: '/cameras/camera',
  cameraAdd: '/cameras/add',
  cameraEdit: '/cameras/edit',
  cameras: '/cameras',
  center: '/center',
  centerAdd: '/center/add',
  centerEdit: '/center/edit',
  dashboard: '/dashboard',
  home: '/home',
  locations: '/locations',
  login: '/login',
  nurseCall: '/nurseCalls/nurseCall',
  nurseCallAdd: '/nurseCalls/add',
  nurseCallEdit: '/nurseCalls/edit',
  nurseCalls: '/nurseCalls',
  pageNotFound: '/not-found',
  reports: '/reports',
  resident: '/resident',
  residentAdd: '/resident/add',
  residentEdit: '/resident/edit',
  nurseReport: '/nurse-report',
  sleepReport: '/sleep-report',
  vitalsReport: '/vitals-report',
  residents: '/residents',
  root: '/',
  sensor: '/sensors/sensor',
  sensorAdd: '/sensors/add',
  sensorEdit: '/sensors/edit',
  sensors: '/sensors',
  userSettings: '/user/settings',
  userProfile: '/user/profile',
  userProfileEdit: '/user/profile/edit',

  // DEV
  dev: '/dev',
  devContext: '/dev/context',
  devBreakpoints: '/dev/breakpoints',
};

export const routes = {
  // /admin is reserved for the admin app
  admin: uri(basepath.admin),
  about: uri(basepath.about),
  alert: uri(basepath.alert),
  alerts: uri(basepath.alerts),
  alertSettingsList: uri(basepath.alertSettingsList),
  alertSettingsEdit: uri(basepath.alertSettingsEdit),
  alertSettingsSensor: uri(basepath.alertSettingsSensor),
  camera: uri(basepath.camera),
  cameraAdd: uri(basepath.cameraAdd),
  cameraEdit: uri(basepath.cameraEdit),
  cameras: uri(basepath.cameras),
  center: uri(basepath.center),
  centerAdd: uri(basepath.centerAdd),
  centerEdit: uri(basepath.centerEdit),
  dashboard: uri(basepath.dashboard),
  home: uri(basepath.home),
  locations: uri(basepath.locations),
  login: uri(basepath.login),
  nurseCall: uri(basepath.nurseCall),
  nurseCallAdd: uri(basepath.nurseCallAdd),
  nurseCallEdit: uri(basepath.nurseCallEdit),
  nurseCalls: uri(basepath.nurseCalls),
  pageNotFound: uri(basepath.pageNotFound),
  reports: uri(basepath.reports),
  resident: uri(basepath.resident),
  residentAdd: uri(basepath.residentAdd),
  residentEdit: uri(basepath.residentEdit),
  nurseReport: uri(basepath.nurseReport),
  sleepReport: uri(basepath.sleepReport),
  vitalsReport: uri(basepath.vitalsReport),
  residents: uri(basepath.residents),
  root: uri(basepath.root),
  sensor: uri(basepath.sensor),
  sensorAdd: uri(basepath.sensorAdd),
  sensorEdit: uri(basepath.sensorEdit),
  sensors: uri(basepath.sensors),
  userSettings: uri(basepath.userSettings),
  userProfile: uri(basepath.userProfile),
  userProfileEdit: uri(basepath.userProfileEdit),

  // DEV
  dev: uri(basepath.dev),
  devContext: uri(basepath.devContext),
  devBreakpoints: uri(basepath.devBreakpoints),
};

export const ACRoutes: Array<ACRouteMatcher> = [
  {
    path: routes.about,
    component: Views.AboutView,
    match: (path: string) => path.endsWith(routes.about),
  },
  {
    path: routes.admin,
    component: Views.AdminView,
    match: (path: string) => path.endsWith(routes.admin),
  },
  {
    path: routes.alert,
    component: Views.AlertView,
    match: (path: string) => path.endsWith(routes.alert),
  },
  {
    path: routes.alerts,
    component: Views.AlertListView,
    match: (path: string) => path.endsWith(routes.alerts),
  },
  {
    path: routes.center,
    component: Views.HomeView,
    match: (path: string) => path.endsWith(routes.center),
  },
  {
    path: routes.cameras,
    component: Views.CameraListView,
    match: (path: string) => path.endsWith(routes.cameras),
  },
  {
    path: routes.camera,
    component: Views.CameraView,
    match: (path: string) => path.endsWith(routes.camera),
  },
  {
    path: routes.cameraAdd,
    component: Views.CameraEditView,
    match: (path: string) => path.endsWith(routes.cameraAdd),
  },
  {
    path: routes.cameraEdit,
    component: Views.CameraEditView,
    match: (path: string) => path.endsWith(routes.cameraEdit),
  },
  {
    path: routes.centerAdd,
    component: Views.CareCenterEditView,
    match: (path: string) => path.endsWith(routes.centerAdd),
  },
  {
    path: routes.centerEdit,
    component: Views.CareCenterEditView,
    match: (path: string) => path.endsWith(routes.centerEdit),
  },
  {
    path: routes.dashboard,
    component: Views.DashboardView,
    match: (path: string) => path.endsWith(routes.dashboard),
  },
  {
    path: routes.sensors,
    component: Views.DeviceListView,
    match: (path: string) => path.endsWith(routes.sensors),
  },
  {
    path: routes.sensor,
    component: Views.DeviceView,
    match: (path: string) => path.endsWith(routes.sensor),
  },
  {
    path: routes.sensorAdd,
    component: Views.DeviceEditView,
    match: (path: string) => path.endsWith(routes.sensorAdd),
  },
  {
    path: routes.sensorEdit,
    component: Views.DeviceEditView,
    match: (path: string) => path.endsWith(routes.sensorEdit),
  },
  {
    path: routes.dev,
    component: Views.DevView,
    match: (path: string) => path.endsWith(routes.dev),
  },
  {
    path: routes.devContext,
    component: Views.DevContextView,
    match: (path: string) => path.endsWith(routes.devContext),
  },
  {
    path: routes.home,
    component: Views.HomeView,
    match: (path: string) => path.endsWith(routes.home),
  },
  {
    path: routes.locations,
    component: Views.LocationsView,
    match: (path: string) => path.endsWith(routes.locations),
  },
  {
    path: routes.nurseCalls,
    component: Views.NurseCallListView,
    match: (path: string) => path.endsWith(routes.nurseCalls),
  },
  {
    path: routes.nurseCall,
    component: Views.NurseCallView,
    match: (path: string) => path.endsWith(routes.nurseCall),
  },
  {
    path: routes.nurseCallAdd,
    component: Views.NurseCallEditView,
    match: (path: string) => path.endsWith(routes.nurseCallAdd),
  },
  {
    path: routes.nurseCallEdit,
    component: Views.NurseCallEditView,
    match: (path: string) => path.endsWith(routes.nurseCallEdit),
  },
  {
    path: routes.reports,
    component: Views.ReportsView,
    match: (path: string) => path.endsWith(routes.reports),
  },
  {
    path: routes.resident,
    component: Views.ResidentView,
    match: (path: string) => path.endsWith(routes.resident),
  },
  {
    path: routes.residentAdd,
    component: Views.ResidentEditView,
    match: (path: string) => path.endsWith(routes.residentAdd),
  },
  {
    path: routes.residentEdit,
    component: Views.ResidentEditView,
    match: (path: string) => path.endsWith(routes.residentEdit),
  },
  {
    path: routes.nurseReport,
    component: Views.NurseReportView,
    match: (path: string) => path.endsWith(routes.nurseReport),
  },
  {
    path: routes.sleepReport,
    component: Views.SleepReportView,
    match: (path: string) => path.endsWith(routes.sleepReport),
  },
  {
    path: routes.vitalsReport,
    component: Views.VitalsReportView,
    match: (path: string) => path.endsWith(routes.vitalsReport),
  },
  {
    path: routes.residents,
    component: Views.ResidentListView,
    match: (path: string) => path.endsWith(routes.residents),
  },
  // {
  //   path: routes.root,
  //   component: Views.DashboardView,
  //   match: (path: string) => path.endsWith(routes.root),
  // },
  {
    path: routes.alertSettingsList,
    component: Views.AlertSettingsListView,
    match: (path: string) => path.endsWith(routes.alertSettingsList),
  },
  {
    path: routes.alertSettingsEdit,
    component: Views.AlertSettingsEditView,
    match: (path: string) => path.endsWith(routes.alertSettingsEdit),
  },
  {
    path: routes.alertSettingsSensor,
    component: Views.AlertSettingsView,
    match: (path: string) => path.endsWith(routes.alertSettingsSensor),
  },
  {
    path: routes.userSettings,
    component: Views.UserSettingsView,
    match: (path: string) => path.endsWith(routes.userSettings),
  },
  {
    path: routes.userProfile,
    component: Views.UserProfileView,
    match: (path: string) => path.endsWith(routes.userProfile),
  },
  {
    path: routes.userProfileEdit,
    component: Views.UserProfileView,
    match: (path: string) => path.endsWith(routes.userProfileEdit),
  },
  {
    path: routes.root,
    component: Views.DashboardView,
    match: (path: string) => path === routes.root || path === deployment.pathname,
  },
  {
    path: routes.pageNotFound,
    component: isHybrid() ? Views.DashboardView : Views.PageNotFound,
    match: () => true,
  },
];

logger.debug(routes);
