"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isNurseCall = exports.isNurseCallDataModel = exports.NurseCallStatus = exports.NurseCallType = void 0;
// Supported nurse call device types
var NurseCallType;
(function (NurseCallType) {
    NurseCallType["BestReha"] = "BestReha";
})(NurseCallType || (exports.NurseCallType = NurseCallType = {}));
var NurseCallStatus;
(function (NurseCallStatus) {
    NurseCallStatus[NurseCallStatus["active"] = 0] = "active";
    NurseCallStatus[NurseCallStatus["inactive"] = 1] = "inactive";
})(NurseCallStatus || (exports.NurseCallStatus = NurseCallStatus = {}));
// type guards
function isNurseCallDataModel(object) {
    const _obj = object;
    if (_obj.deviceType === NurseCallType.BestReha) {
        return _obj.nurseCallId !== undefined;
    }
    return false;
}
exports.isNurseCallDataModel = isNurseCallDataModel;
function isNurseCall(object) {
    const _obj = object;
    return _obj.data !== undefined && isNurseCallDataModel(_obj.data);
}
exports.isNurseCall = isNurseCall;
