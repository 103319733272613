"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultNurseCallAlertSettings = void 0;
const logger_1 = require("../logger");
const models_1 = require("../models");
const errors_1 = require("../errors");
const NurseCall_1 = require("../models/NurseCall");
const store = __importStar(require("./DatabaseOps"));
const logger = (0, logger_1.getLogger)('/services/NurseCallService', 'info');
logger.debug();
const defaultNurseCallAlertSettings = () => {
    return {
        processNurseCallAlerts: true,
    };
};
exports.defaultNurseCallAlertSettings = defaultNurseCallAlertSettings;
class NurseCallService {
    constructor(db) {
        this.nurseCall = (snapshot) => {
            return { snapshot, id: snapshot.id, data: this.data(snapshot) };
        };
        this.refresh = (nurseCall) => __awaiter(this, void 0, void 0, function* () {
            const r = yield this.findById(nurseCall.snapshot.id);
            if (r) {
                return this.nurseCall(r.snapshot);
            }
        });
        this.data = (snapshot) => {
            const data = this.db.data(snapshot);
            if (data !== undefined) {
                // perform any necessary updates...
                if (data.iccid === undefined) {
                    data.iccid = null;
                }
                if (data.firmmwareVersion === undefined) {
                    data.firmmwareVersion = null;
                }
            }
            return data;
        };
        this.load = (conditions) => __awaiter(this, void 0, void 0, function* () {
            const snapshots = yield this.db.load(models_1.Collection.nurse_calls, null, conditions);
            const nurseCalls = [];
            snapshots.forEach((snapshot) => {
                nurseCalls.push(this.nurseCall(snapshot));
            });
            return nurseCalls;
        });
        this.findById = (id) => this.db.findById(models_1.Collection.nurse_calls, id);
        this.findByNurseCallId = (nurseCallId) => __awaiter(this, void 0, void 0, function* () {
            const nurseCalls = yield this.load([store.where.eq('nurseCallId', nurseCallId)]);
            if (nurseCalls.length > 0) {
                return nurseCalls[0];
            }
            return undefined;
        });
        this.findByRef = (ref) => this.db.findByRef(ref);
        this.getCareCenter = (nurseCall) => this.db.careCenters.findByRef(nurseCall.data.careCenterRef);
        this.getResident = (nurseCall) => __awaiter(this, void 0, void 0, function* () {
            if (nurseCall.data.residentRef) {
                return this.db.residents.findByRef(nurseCall.data.residentRef);
            }
            return undefined;
        });
        this.getNurseCalls = (center) => this.load(center ? [store.where.eq('careCenterRef', center.snapshot.ref.path)] : []);
        this.getResidentNurseCalls = (resident) => this.load([store.where.eq('residentRef', resident.snapshot.ref.path)]);
        this.getUnassignedNurseCalls = (center) => this.load([
            store.where.eq('residentRef', null),
            store.where.eq('careCenterRef', center.snapshot.ref.path),
        ]);
        this.addNurseCall = (data) => __awaiter(this, void 0, void 0, function* () {
            const nc = yield this.db.add(models_1.Collection.nurse_calls, data);
            yield this.sendNotifications(nc, data.status);
            return nc;
        });
        this.updateNurseCall = (nurseCall) => __awaiter(this, void 0, void 0, function* () {
            const nc = yield this.db.update(nurseCall);
            yield this.sendNotifications(nc, nc.data.status);
            return nc;
        });
        this.deleteNurseCall = (nurseCall) => __awaiter(this, void 0, void 0, function* () {
            if (nurseCall.snapshot) {
                // deleted so no longer active - send notification before deleting
                yield this.sendNotifications(nurseCall, NurseCall_1.NurseCallStatus.inactive);
                const nc = yield this.db.delete(nurseCall);
                return nc;
            }
            else {
                return Promise.reject('cannot delete: nurseCall has no snapshot');
            }
        });
        this.hardDeleteNurseCall = (nurseCall) => __awaiter(this, void 0, void 0, function* () {
            if (nurseCall.snapshot) {
                // deleted so no longer active - send notification before deleting
                yield this.sendNotifications(nurseCall, NurseCall_1.NurseCallStatus.inactive);
                const nc = yield this.db.hardDelete(nurseCall);
                return nc;
            }
            else {
                return Promise.reject('cannot delete: nurseCall has no snapshot');
            }
        });
        /**
         * Updates alert settings for a resident at the Aisleep server.
         *
         * @param db the AC database
         * @param resident the resident who's settings are being changed
         */
        this.nurseCallUpdateAlertSettings = (resident, settings) => __awaiter(this, void 0, void 0, function* () {
            if (logger.isDebugEnabled()) {
                logger.debug('nurseCallUpdateAlertSettings()');
                logger.debug({ resident: resident.data, settings });
            }
            if (!this.db.user) {
                throw new errors_1.ACError('AC DB connection is not authenticated', errors_1.ACErrorCodes.ErrorNotAuthenticated);
            }
            const center = yield this.db.careCenters.findByRef(resident.data.careCenterRef);
            if (!center) {
                throw new errors_1.ACError('Resident assigned to patient does not exist.', errors_1.ACErrorCodes.ErrorInvalidCareCenterId);
            }
            const sensorSettings = yield this.db.sensorAlertSettings.getByResident(resident);
            sensorSettings.data.nurseCall = settings;
            return this.db.sensorAlertSettings.update(sensorSettings);
        });
        /**
         * Listen for nurseCall changes - triggered when new image is available
         * @param nameSpace string the name space for the listener. Only one
         * listener per nurseCall per name space is allowed.
         */
        this.listen = (nameSpace, sensor, changed) => this.db.listeners.listen(nameSpace, sensor.snapshot, (snapshot) => {
            const _nurseCall = this.nurseCall(snapshot);
            if (_nurseCall.data !== undefined) {
                changed(_nurseCall);
            }
        });
        /**
         * Stop listening for nurseCall changes.
         */
        this.stopListening = (listener) => this.db.listeners.stop(listener);
        /**
         * Returns true if the nurse call is a 'Best Reha' device.
         */
        this.isBestReha = (nurseCall) => nurseCall.data.deviceType === 'BestReha';
        /**
         * Returns the current battery status of the nurse call device.
         * @param nurseCall
         * @returns 'LowCharge' | 'ChargingComplete' | 'Shutdown' | 'ConnectAdapter' | 'DisconnectAdapter' | 'Unknown'
         */
        this.getBatteryStatus = (nurseCall) => {
            if (this.isBestReha(nurseCall)) {
                switch (nurseCall.data.batteryStatus) {
                    case 1:
                        return 'LowCharge';
                    case 2:
                        return 'ChargingComplete';
                    case 3:
                        return 'Shutdown';
                    case 4:
                        return 'ConnectAdapter';
                    case 10:
                        return 'DisconnectAdapter';
                }
            }
            return 'Unknown';
        };
        /**
         * Returns the time when the battery status was last updated. Or null if the time is unknown.
         * @param nurseCall
         * @returns Date
         */
        this.getBatteryStatusTime = (nurseCall) => nurseCall.data.batteryStatusTime > 0 ? new Date(nurseCall.data.batteryStatusTime) : null;
        /**
         * Returns the battery level as a percentage. Or undefined if the battery level is unknown.
         * @param nurseCall
         * @returns number
         */
        this.getBatteryLevelPercent = (nurseCall) => {
            if (this.isBestReha(nurseCall)) {
                switch (nurseCall.data.batteryLevel) {
                    case -1:
                        return 0;
                    case 0:
                        return 0.1;
                    case 1:
                        return 0.3;
                    case 2:
                        return 0.6;
                    case 3:
                        return 1;
                }
            }
            return undefined;
        };
        /**
         * Returns the time when the battery level was last updated. Or null if the time is unknown.
         * @param nurseCall
         * @returns Date
         */
        this.getBatteryLevelTime = (nurseCall) => nurseCall.data.heartbeatTime > 0 ? new Date(nurseCall.data.heartbeatTime) : null;
        /**
         * Returns the charging status of the nurse call device.
         * @param nurseCall
         * @returns 'Charging' | 'NotCharged' | 'Charged' | 'Unknown'
         */
        this.getChargingStatus = (nurseCall) => {
            if (this.isBestReha(nurseCall)) {
                switch (nurseCall.data.chargingStatus) {
                    case 0:
                        return 'NotCharged';
                    case 1:
                        return 'Charging';
                    case 2:
                        return 'Charged';
                }
            }
            return 'Unknown';
        };
        /**
         * Returns the time when the charge status was last updated. Or null if the time is unknown.
         * @param nurseCall
         * @returns Date
         */
        this.getChargeStatusTime = (nurseCall) => nurseCall.data.heartbeatTime > 0 ? new Date(nurseCall.data.heartbeatTime) : null;
        /**
         * Send any notifications to systems that need to be updated with the current status.
         * Currently only needed for BestReha devices.
         */
        this.sendNotifications = (nurseCall, status) => __awaiter(this, void 0, void 0, function* () {
            if (this.isBestReha(nurseCall)) {
                const api = yield this.db.api();
                const _status = status === NurseCall_1.NurseCallStatus.active ? 0 : 1;
                api.nurseCall.sendNotificationToBestReha({
                    imei: nurseCall.data.nurseCallId,
                    nurseCallBestRehaStatus: {
                        status: _status,
                    },
                });
            }
        });
        this.db = db;
    }
}
exports.default = NurseCallService;
