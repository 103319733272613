import {
  Button,
  CssBaseline,
  IconButton,
  InputAdornment,
  LinearProgress,
  Paper,
  TextField,
} from '@mui/material';
import classNames from 'classnames';
import * as EmailValidator from 'email-validator';
import { Component, Fragment, createRef } from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import * as device from '../device';
import { isHybrid } from '../hybrid';
import * as Icons from '../icons';
import strings from '../languages';
import { getLogger } from '../logger';
import { PrimaryLogo as Logo } from './Logos';

const logger = getLogger('components/LoginForm');
logger.debug();

type Focus = 'domain' | 'username' | 'password';

interface Props {
  className?: string;
  domain?: string;
  username?: string;
  password?: string;
  includeDomain?: boolean;
  authenticating?: boolean;
  logo?: boolean;
  width?: device.Width;
  authErrorType?: 'domain' | 'signIn' | 'username' | 'password' | undefined;
  // focus?: Focus;
  onLogin: (email: string, password: string, domain?: string) => void;
  onForgotPassword: () => void;
}

interface State {
  focus: Focus | null;
  domain?: string;
  domainError?: string;
  username?: string;
  usernameError?: string;
  password?: string;
  passwordError?: string;
  forgotPassword?: boolean;
  showPassword?: boolean;
}

type AllProps = Props & WrappedComponentProps;

// interface PropsWithStyles extends WithStyles<typeof styles>;

class LoginForm extends Component<AllProps, State> {
  readonly state: State = {
    focus: 'username',
  };

  domainRef = createRef<HTMLInputElement>();
  usernameRef = createRef<HTMLInputElement>();
  passwordRef = createRef<HTMLInputElement>();

  componentDidMount(): void {
    this.setState({
      domain: this.props.domain,
      username: this.props.username,
      password: this.props.password,
      showPassword: false,
    });
  }

  componentDidUpdate(prevProps: AllProps): void {
    if (this.props.domain !== prevProps.domain && this.props.domain !== this.state.domain) {
      this.setState({ domain: this.props.domain });
    }
    logger.debug(this.state);
  }

  onLogin = () => {
    const { intl, onLogin: login, includeDomain } = this.props;
    const { username, password, domain } = this.state;
    const localized = strings(intl);

    let focus: Focus | null = null;

    if (includeDomain) {
      if (!domain) {
        focus = 'domain';
        this.setState({
          domainError: localized.validation.requiredField(),
        });
      } else {
        this.setState({ domainError: undefined });
      }
    }
    if (!username) {
      if (!focus) {
        focus = 'username';
      }
      this.setState({
        usernameError: localized.validation.requiredField(),
      });
    } else if (!EmailValidator.validate(username)) {
      if (!focus) {
        focus = 'username';
      }
      this.setState({
        usernameError: localized.validation.invalidEmail(),
      });
    } else {
      this.setState({ usernameError: undefined });
    }
    if (!password) {
      if (!focus) {
        focus = 'password';
      }
      this.setState({
        passwordError: localized.validation.requiredField(),
      });
    } else {
      this.setState({ passwordError: undefined });
    }
    if (
      username &&
      password &&
      (!includeDomain || domain) &&
      !this.state.domainError &&
      !this.state.usernameError &&
      !this.state.passwordError
    ) {
      login(username, password, domain);
    } else {
      if (includeDomain && focus === 'domain') {
        if (this.domainRef.current) this.domainRef.current.focus();
      } else if (focus === 'username') {
        if (this.usernameRef.current) this.usernameRef.current.focus();
      } else if (focus === 'password') {
        if (this.passwordRef.current) this.passwordRef.current.focus();
      }
    }
  };

  onForgotPassword = () => {
    this.props.onForgotPassword();
  };

  onFocusDomain = () => {
    // place holder
  };

  onBlurDomain = () => {
    // this.setState({ focus: null });
  };

  onChangeDomain = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      domain: event.target.value,
      domainError: undefined,
    });
  };

  onFocusUsername = () => {
    // place holder
  };

  onBlurUsername = () => {
    // this.setState({ focus: null });
  };

  onChangeUsername = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      username: event.target.value,
      usernameError: undefined,
    });
  };

  onFocusPassword = () => {
    // place holder
  };

  onBlurPassword = () => {
    // this.setState({ focus: null });
  };

  onChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      password: event.target.value,
      passwordError: undefined,
    });
  };

  // onKeyPressPassword = (event: React.KeyboardEvent<HTMLDivElement>) => {
  //   if (event.charCode === 13) {
  //     event.preventDefault();
  //     this.onLogin();
  //   }
  // };

  onKeyPressEnter = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const { includeDomain } = this.props;
    const { username, password, domain } = this.state;

    if (event.charCode === 13) {
      event.preventDefault();
      if (includeDomain && domain === '') {
        if (this.domainRef.current) this.domainRef.current.focus();
      } else if (username === '') {
        if (this.usernameRef.current) this.usernameRef.current.focus();
      } else if (password === '') {
        if (this.passwordRef.current) this.passwordRef.current.focus();
      } else {
        this.onLogin();
      }
    }
  };

  render() {
    const { intl, includeDomain, authenticating, logo, width, authErrorType } = this.props;
    const { usernameError, passwordError, domainError } = this.state;
    // const { focusPassword, focusUsername } = this.state;
    // const iconPasswordClasses = focusPassword && !passwordError ? classes.iconFocus : classes.icon;
    // const iconUsernameClasses = focusUsername && !usernameError ? classes.iconFocus : classes.icon;
    const localized = strings(intl);

    const verifyHint = localized.validation.verifyField();

    const domainField = includeDomain ? (
      <TextField
        value={this.state.domain}
        inputRef={this.domainRef}
        label={localized.login.domain()}
        id="domain"
        name="domain"
        autoComplete="domain"
        // focused={focus === 'domain'}
        autoFocus={includeDomain}
        onFocus={this.onFocusDomain}
        // onBlur={this.onBlurDomain}
        onChange={this.onChangeDomain}
        onKeyPress={this.onKeyPressEnter}
        error={this.state.domainError ? true : authErrorType === 'domain' ? true : false}
        helperText={domainError ? domainError : authErrorType === 'domain' ? verifyHint : false}
        fullWidth
        required
      />
    ) : null;

    const handleClickShowPassword = () => {
      this.setState({ showPassword: !this.state.showPassword });
    };

    return (
      <Fragment>
        <CssBaseline />
        <Paper
          elevation={device.isSmallOrSmaller(width) ? 0 : 2}
          className={classNames('AC-loginPaper', authenticating ? 'AC-loginAuthenticating' : null)}
        >
          {authenticating ? (
            <>
              {logo && (
                <Logo className="AC-loginLogo" width={200} ignoreTenant={isHybrid() && false} />
              )}
              <LinearProgress className="AC-loginProgressBar" />
            </>
          ) : (
            <>
              {logo && (
                <Logo className="AC-loginLogo" width={200} ignoreTenant={isHybrid() && false} />
              )}
              {domainField}
              <TextField
                inputRef={this.usernameRef}
                label={localized.login.email()}
                id="email"
                name="email"
                autoComplete="email"
                type="email"
                // focused={focus === 'username'}
                autoFocus={!includeDomain}
                onFocus={this.onFocusUsername}
                // onBlur={this.onBlurUsername}
                onChange={this.onChangeUsername}
                onKeyPress={this.onKeyPressEnter}
                error={this.state.usernameError ? true : authErrorType === 'signIn' ? true : false}
                helperText={
                  usernameError ? usernameError : authErrorType === 'signIn' ? verifyHint : false
                }
                value={this.state.username}
                fullWidth
                required
              />
              <TextField
                inputRef={this.passwordRef}
                label={localized.login.password()}
                id="password"
                name="password"
                autoComplete="current-password"
                // focused={focus === 'password'}
                type={this.state.showPassword ? 'text' : 'password'}
                onFocus={this.onFocusPassword}
                // onBlur={this.onBlurPassword}
                onChange={this.onChangePassword}
                onKeyPress={this.onKeyPressEnter}
                error={this.state.passwordError ? true : authErrorType === 'signIn' ? true : false}
                helperText={
                  passwordError ? passwordError : authErrorType === 'signIn' ? verifyHint : false
                }
                value={this.state.password}
                fullWidth
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        // onMouseDown={handleMouseDownPassword}
                      >
                        {this.state.showPassword ? <Icons.Visibility /> : <Icons.VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                className="AC-loginSubmit"
                type="submit"
                fullWidth={true}
                variant="contained"
                onClick={this.onLogin}
                size="large"
              >
                {localized.login.signIn()}
              </Button>
              <Button size="small" onClick={this.onForgotPassword}>
                {localized.login.forgotPassword()}
              </Button>
            </>
          )}
        </Paper>
      </Fragment>
    );
  }
}

export default injectIntl(LoginForm);
