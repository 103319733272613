import { Store as Database } from 'attentive-connect-store/dist';
import { Resident } from 'attentive-connect-store/dist/models';
import { StringsIntl } from '../languages';
import * as utils from './Utils';

export const DEFAULT_ALERTS_PAUSED_DURATION = 20 * 60; // 20 mins
export const ALERTS_STOPPED_DURATION = -1;
export const ALERTS_RESUMED_DURATION = 0;

/**
 * Resident's full name.
 */
export const getFullName = (resident: Resident, l10n: StringsIntl) => {
  const { firstName, lastName } = resident.data;
  const fullName = l10n.person.fullName(firstName, lastName);

  return fullName;
};

export const getRoom = (resident: Resident, l10n: StringsIntl) => {
  const { room } = resident.data;

  if (room && room.length > 0) {
    return l10n.label.room() + ' ' + room;
  }

  return '';
};

export const getBed = (resident: Resident, l10n: StringsIntl) => {
  const { bed } = resident.data;

  if (bed && bed.length > 0) {
    return l10n.label.bed() + ' ' + bed;
  }

  return '';
};

export const getFloor = (resident: Resident, l10n: StringsIntl) => {
  const { floor } = resident.data;

  if (floor) {
    return l10n.label.floor() + ' ' + floor;
  }

  return '';
};

export const getLocation = (resident: Resident, l10n: StringsIntl, max = 2) => {
  const room = getRoom(resident, l10n);
  const bed = getBed(resident, l10n);
  const floor = getFloor(resident, l10n);
  let count = 0;

  let location = '';

  if (bed.length > 0 && count < max) {
    location = location + (location.length > 0 ? ', ' : '') + bed;
    count++;
  }
  if (room.length > 0 && count < max) {
    location = room + (location.length > 0 ? ', ' : '') + location;
    count++;
  }
  if (floor.length > 0 && count < max) {
    location = floor + (location.length > 0 ? ', ' : '') + location;
    count++;
  }

  return location;
};

export const residentCompare = (a: Resident, b: Resident, localized: StringsIntl) => {
  if (a.snapshot.id === b.snapshot.id) {
    return 0;
  }

  const aName = utils.pad(localized.person.fullName(a.data.firstName, a.data.lastName), 64);
  const bName = utils.pad(localized.person.fullName(b.data.firstName, b.data.lastName), 64);
  let compare = aName.localeCompare(bName);

  if (compare === 0) {
    compare = roomCompare(a, b, localized);
  }
  if (compare === 0) {
    compare = bedCompare(a, b, localized);
  }
  if (compare === 0) {
    compare = floorCompare(a, b, localized);
  }

  return compare;
};

export const highPriorityDispCompare = (a: Resident, b: Resident) => {
  if (a.snapshot.id === b.snapshot.id) {
    return 0;
  } else if (Number(a.data.highPriority) === Number(b.data.highPriority)) {
    return 0;
  } else if (Number(a.data.highPriority) && Number(!b.data.highPriority)) {
    return -1;
  } else if (Number(b.data.highPriority) && Number(!a.data.highPriority)) {
    return 1;
  } else if (Number(a.data.highPriority) && Number(b.data.highPriority)) {
    // simulate a numerical compare by pre-pending 0's to make the strings equal length
    return utils
      .pad(Number(a.data.highPriority), 64, '0')
      .localeCompare(utils.pad(Number(b.data.highPriority), 64, '0'));
  }

  return 0;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const roomCompare = (a: Resident, b: Resident, localized: StringsIntl) => {
  if (a.snapshot.id === b.snapshot.id) {
    return 0;
  } else if (a.data.room === b.data.room) {
    return 0;
  } else if (a.data.room && !b.data.room) {
    return 1;
  } else if (b.data.room && !a.data.room) {
    return -1;
  } else if (a.data.room && b.data.room) {
    // simulate a numerical compare by pre-pending 0's to make the strings equal length
    return utils.pad(a.data.room, 64, '0').localeCompare(utils.pad(b.data.room, 64, '0'));
  }

  return 0;
};

export const bedCompare = (a: Resident, b: Resident, localized: StringsIntl) => {
  if (a.snapshot.id === b.snapshot.id) {
    return 0;
  }
  if (a.data.bed === b.data.bed) {
    return localized.person
      .fullName(a.data.firstName, a.data.lastName)
      .localeCompare(localized.person.fullName(b.data.firstName, b.data.lastName));
  }
  if (a.data.bed && !b.data.bed) {
    return 1;
  }
  if (b.data.bed && !a.data.bed) {
    return -1;
  }
  if (a.data.bed && b.data.bed) {
    // simulate a numerical compare by pre-pending 0's to make the strings equal length
    return utils.pad(a.data.bed, 64, '0').localeCompare(utils.pad(b.data.bed, 64, '0'));
  }

  return 0;
};

export const floorCompare = (a: Resident, b: Resident, localized: StringsIntl) => {
  if (a.snapshot.id === b.snapshot.id) {
    return 0;
  }
  if (a.data.floor === b.data.floor) {
    return localized.person
      .fullName(a.data.firstName, a.data.lastName)
      .localeCompare(localized.person.fullName(b.data.firstName, b.data.lastName));
  }
  if (a.data.floor !== null && b.data.floor === null) {
    return 1;
  }
  if (b.data.floor === null && a.data.floor !== null) {
    return -1;
  }
  if (a.data.floor !== null && b.data.floor !== null) {
    return utils.pad(a.data.floor, 64, '0').localeCompare(utils.pad(b.data.floor, 64, '0'));
  }

  return 0;
};

export const enableWarnings = (database: Database, resident: Resident) => {
  return database.residents.enableWarnings(resident);
};

export const disableWarnings = (database: Database, resident: Resident) => {
  return database.residents.disableWarnings(resident);
};

export const enableHighPriority = (database: Database, resident: Resident) => {
  return database.residents.enableHighPriority(resident);
};

export const disableHighPriority = (database: Database, resident: Resident) => {
  return database.residents.disableHighPriority(resident);
};

export const pauseAlerts = (database: Database, resident: Resident) => {
  return setAlertsPausedDuration(database, resident, DEFAULT_ALERTS_PAUSED_DURATION);
};

export const stopAlerts = (database: Database, resident: Resident) => {
  return setAlertsPausedDuration(database, resident, ALERTS_STOPPED_DURATION);
};

export const resumeAlerts = (database: Database, resident: Resident) => {
  return setAlertsPausedDuration(database, resident, ALERTS_RESUMED_DURATION);
};

const setAlertsPausedDuration = (database: Database, resident: Resident, duration: number) => {
  if (duration === ALERTS_RESUMED_DURATION) {
    // enabled
    return database.residents.enableAlerts(resident);
  } else {
    // disabled
    return database.residents.disableAlerts(resident, duration);
  }
};
