"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Environment = exports.isHybrid = exports.useApi = exports.isNode = void 0;
const logger_1 = require("../logger");
const Firebase_1 = require("../services/Firebase");
const logger = (0, logger_1.getLogger)('/config/Environment', 'info');
// check if running in node env
exports.isNode = typeof process !== 'undefined' && process.versions != null && process.versions.node != null;
// if running in browser we need to use the api calls.
exports.useApi = !exports.isNode;
// if running a hybrid app (i.e. cordova or capacitor)
const isHybrid = () => {
    if (!exports.isNode && window) {
        const w = window;
        return w.cordova !== undefined;
    }
    return false;
};
exports.isHybrid = isHybrid;
// window.ac is initialized in index.html
// this will only be defined when running in browser
// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
const globalConfig = exports.isNode ? undefined : window.ac;
class Environment {
    constructor(config) {
        //
        // BioBeat
        //
        this.biobeat = {
            // for get-measurement
            apiUrl: process.env.BIOBEAT_API_URL || '',
            rrr: process.env.BIOBEAT_RRR || '',
            bbb: process.env.BIOBEAT_BBB || '',
            // for authentication
            authUrl: process.env.BIOBEAT_AUTH_URL || '',
            qqq: process.env.BIOBEAT_QQQ || '',
            eee: process.env.BIOBEAT_EEE || '',
        };
        //
        // BigQuery
        //
        this.bigquery = {
            datasetName: process.env.BQ_DATASET || 'base_dataset',
            vitalsTable: process.env.BQ_VITALS || 'vitals',
            acVitalsTable: process.env.BQ_AC_VITALS || 'ac_vitals',
            acAlertsTable: process.env.BQ_AC_ALERTS || 'ac_alerts',
            noomiVitalsTable: process.env.BQ_NOOMI_VITALS || 'noomi_vitals',
            noomiSleepSessionTable: process.env.BQ_NOOMI_SLEEP_SESSION || 'noomi_sleep_sessions',
            aisleepDailyLogTable: process.env.BQ_AISLEEP_DAILY_LOG || 'aisleep_daily_log',
            aisleepVitalsTable: process.env.BQ_AISLEEP_VITALS || 'aisleep_vitals',
        };
        //
        // AiSleep
        //
        this.aisleep = {
            skey: process.env.AISLEEP_SKEY || undefined,
            // AC-815 - amout of time after status changes to out of bed that should elapse
            // before bed exit alerts will be ignored.
            bedExitVitalsTimeSec: process.env.AISLEEP_BED_EXIT_VITALS_TIME_SEC &&
                !isNaN(parseInt(process.env.AISLEEP_BED_EXIT_VITALS_TIME_SEC))
                ? parseInt(process.env.AISLEEP_BED_EXIT_VITALS_TIME_SEC)
                : 15,
            sittingLifeSpanWhileMovingTimeSec: process.env.AISLEEP_SITTING_LIFE_SPAN_WHILE_MOVING_TIME_SEC &&
                !isNaN(parseInt(process.env.AISLEEP_SITTING_LIFE_SPAN_WHILE_MOVING_TIME_SEC))
                ? parseInt(process.env.AISLEEP_SITTING_LIFE_SPAN_WHILE_MOVING_TIME_SEC)
                : 0,
            sittingLifeSpanWhileAwakeTimeSec: process.env.AISLEEP_SITTING_LIFE_SPAN_WHILE_AWAKE_TIME_SEC &&
                !isNaN(parseInt(process.env.AISLEEP_SITTING_LIFE_SPAN_WHILE_AWAKE_TIME_SEC))
                ? parseInt(process.env.AISLEEP_SITTING_LIFE_SPAN_WHILE_AWAKE_TIME_SEC)
                : 0,
            inBedThresholdForBedExitAlarmTimeSec: process.env.AISLEEP_IN_BED_THRESHOLD_FOR_BED_EXIT_ALARM_TIME_SEC &&
                !isNaN(parseInt(process.env.AISLEEP_IN_BED_THRESHOLD_FOR_BED_EXIT_ALARM_TIME_SEC))
                ? parseInt(process.env.AISLEEP_IN_BED_THRESHOLD_FOR_BED_EXIT_ALARM_TIME_SEC)
                : 30,
            inBedThresholdForSittingAlarmTimeSec: process.env.AISLEEP_IN_BED_THRESHOLD_FOR_SITTING_ALARM_TIME_SEC &&
                !isNaN(parseInt(process.env.AISLEEP_IN_BED_THRESHOLD_FOR_SITTING_ALARM_TIME_SEC))
                ? parseInt(process.env.AISLEEP_IN_BED_THRESHOLD_FOR_SITTING_ALARM_TIME_SEC)
                : 30,
            inBedThresholdForMovingAlarmTimeSec: process.env.AISLEEP_IN_BED_THRESHOLD_FOR_MOVING_ALARM_TIME_SEC &&
                !isNaN(parseInt(process.env.AISLEEP_IN_BED_THRESHOLD_FOR_MOVING_ALARM_TIME_SEC))
                ? parseInt(process.env.AISLEEP_IN_BED_THRESHOLD_FOR_MOVING_ALARM_TIME_SEC)
                : 30,
            inBedThresholdForVitalsAlarmTimeSec: process.env.AISLEEP_IN_BED_THRESHOLD_FOR_VITALS_ALARM_TIME_SEC &&
                !isNaN(parseInt(process.env.AISLEEP_IN_BED_THRESHOLD_FOR_VITALS_ALARM_TIME_SEC))
                ? parseInt(process.env.AISLEEP_IN_BED_THRESHOLD_FOR_VITALS_ALARM_TIME_SEC)
                : 60,
            aisleepLatestVersion: process.env.AISLEEP_LATEST_VERSION || undefined,
            // AC-1041 - Update AiSleep FW version
        };
        //
        // SensingWave
        //
        this.sensingWave = {
            xAuthKey: process.env.SENSING_WAVE_X_AUTH_KEY || undefined,
        };
        this.useAuthEmulator = () => {
            var _a;
            // isNode &&
            return this.emulator !== undefined &&
                this.emulator.authUrl !== undefined &&
                ((_a = this.firebase) === null || _a === void 0 ? void 0 : _a.projectId) === 'demo-ac';
        };
        this.useFirestoreEmulator = () => {
            var _a;
            // isNode &&
            return this.emulator !== undefined &&
                this.emulator.firestoreHost !== undefined &&
                this.emulator.firestorePort !== undefined &&
                ((_a = this.firebase) === null || _a === void 0 ? void 0 : _a.projectId) === 'demo-ac';
        };
        this.isEmulator = () => this.useAuthEmulator() || this.useFirestoreEmulator();
        this.firestoreURL = () => {
            if (this.firebase && this.firebase.databaseURL) {
                return new URL(this.firebase.databaseURL);
            }
            else if (this.useFirestoreEmulator()) {
                const hostname = this.firestoreHostname();
                const port = this.firestorePort();
                if (hostname && port) {
                    return new URL(`http://${hostname}:${port}`);
                }
            }
            return undefined;
        };
        this.firestoreHostname = () => {
            if (this.useFirestoreEmulator() && this.emulator !== undefined && this.emulator.firestoreHost) {
                return this.emulator.firestoreHost;
            }
            if (this.firebase !== undefined && this.firebase.databaseURL) {
                return new URL(this.firebase.databaseURL).hostname;
            }
            return undefined;
        };
        this.firestorePort = () => {
            if (this.useFirestoreEmulator() && this.emulator !== undefined && this.emulator.firestorePort) {
                const port = parseInt(this.emulator.firestorePort);
                if (!isNaN(port)) {
                    return port;
                }
            }
            if (this.firebase !== undefined && this.firebase.databaseURL) {
                const url = new URL(this.firebase.databaseURL);
                if (url.port) {
                    const port = parseInt(url.port);
                    if (!isNaN(port) && port > 0) {
                        return port;
                    }
                }
                if (url.protocol === 'https' || url.protocol === 'https:') {
                    return 443;
                }
                if (url.protocol === 'http' || url.protocol === 'http:') {
                    return 80;
                }
            }
            return undefined;
        };
        //
        // Elmo API
        //
        this.elmo = {
            baseUrl: process.env.ELMO_BASE_URL || undefined,
            clientId: process.env.ELMO_CLIENT_ID || '',
            clientSecret: process.env.ELMO_CLIENT_SECRET || '',
            username: process.env.ELMO_USER_NAME || '',
            password: process.env.ELMO_PASSWORD || '',
            scope: process.env.ELMO_SCOPE || 'elmo-api.all',
        };
        //
        // Firebase Admin for Messaging
        //
        this.firebaseAdminForMessaging = {
            type: process.env.FIREBASE_FOR_MESSAGING_TYPE || undefined,
            projectId: process.env.FIREBASE_FOR_MESSAGING_PROJECT_ID || undefined,
            privateKeyId: process.env.FIREBASE_FOR_MESSAGING_PRIVATE_KEY_ID || undefined,
            privateKey: process.env.FIREBASE_FOR_MESSAGING_PRIVATE_KEY
                ? process.env.FIREBASE_FOR_MESSAGING_PRIVATE_KEY.replace(/\n/gm, '\n')
                : undefined,
            clientEmail: process.env.FIREBASE_FOR_MESSAGING_CLIENT_EMAIL || undefined,
            clientId: process.env.FIREBASE_FOR_MESSAGING_CLIENT_ID || undefined,
            authUri: process.env.FIREBASE_FOR_MESSAGING_AUTH_URI || undefined,
            tokenUri: process.env.FIREBASE_FOR_MESSAGING_TOKEN_URI || undefined,
            authProviderX509CertUrl: process.env.FIREBASE_FOR_MESSAGING_AUTH_PROVIDER_X509_CERT_URL || undefined,
            clientX509CertUrl: process.env.FIREBASE_FOR_MESSAGING_CLIENT_X509_CERT_URL || undefined,
            universeDomain: process.env.FIREBASE_FOR_MESSAGING_UNIVERSE_DOMAIN || undefined,
        };
        if (config) {
            this.loadGlobalConfig(config);
        }
        logger.debug('constructor()', this.getEnvironmentConfig(true));
    }
    get vendor() {
        if (!this._vendor) {
            const v = process.env.REACT_APP_vendor || process.env.APP_vendor || process.env.vendor || 'macnica';
            this._vendor = { id: v === 'toppan' ? 'toppan' : 'macnica' };
        }
        return this._vendor;
    }
    setVendor(vendor) {
        this._vendor = vendor;
        if (logger.isDebugEnabled()) {
            logger.debug('setVendor()', vendor);
        }
    }
    get pathname() {
        if (!this._pathname && !exports.isNode && window.location.href) {
            try {
                const url = new URL(window.location.href);
                const split = url.pathname.split('/');
                if (split.length > 1) {
                    return '/' + split[1];
                }
            }
            catch (e) {
                // ignore
            }
        }
        return this._pathname;
    }
    setPathname(pathname) {
        this._pathname = pathname;
        logger.debug(`setPathName(): ${pathname}`);
    }
    /**
     * If emulator is being used.
     */
    get emulator() {
        if (!this._emulator) {
            this.setEmulator({
                authUrl: process.env.REACT_APP_emulatorAuthUrl || process.env.APP_emulatorAuthUrl,
                firestoreHost: process.env.REACT_APP_emulatorFirestoreHost ||
                    process.env.APP_emulatorFirestoreHost ||
                    'localhost',
                firestorePort: process.env.REACT_APP_emulatorFirestorePort || process.env.APP_emulatorFirestorePort,
            });
        }
        return this._emulator;
    }
    setEmulator(config) {
        this._emulator = config;
        if (logger.isDebugEnabled()) {
            const _conf = this.getEnvironmentConfig(true).emulator;
            logger.debug('setEmulator()', _conf);
        }
    }
    clearEmulator() {
        this._emulator = undefined;
    }
    get firebase() {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        if (!this._firebase) {
            const apiKey = ((_a = globalConfig === null || globalConfig === void 0 ? void 0 : globalConfig.firebase) === null || _a === void 0 ? void 0 : _a.apiKey) ||
                process.env.REACT_APP_FIREBASE_apiKey ||
                process.env.APP_FIREBASE_apiKey ||
                process.env.FIREBASE_apiKey;
            const authDomain = ((_b = globalConfig === null || globalConfig === void 0 ? void 0 : globalConfig.firebase) === null || _b === void 0 ? void 0 : _b.authDomain) ||
                process.env.REACT_APP_FIREBASE_authDomain ||
                process.env.APP_FIREBASE_authDomain ||
                process.env.FIREBASE_authDomain;
            const databaseURL = ((_c = globalConfig === null || globalConfig === void 0 ? void 0 : globalConfig.firebase) === null || _c === void 0 ? void 0 : _c.databaseURL) ||
                process.env.REACT_APP_FIREBASE_databaseURL ||
                process.env.APP_FIREBASE_databaseURL ||
                process.env.FIREBASE_databaseURL;
            const projectId = ((_d = globalConfig === null || globalConfig === void 0 ? void 0 : globalConfig.firebase) === null || _d === void 0 ? void 0 : _d.projectId) ||
                process.env.REACT_APP_FIREBASE_projectId ||
                process.env.APP_FIREBASE_projectId ||
                process.env.FIREBASE_projectId;
            const storageBucket = ((_e = globalConfig === null || globalConfig === void 0 ? void 0 : globalConfig.firebase) === null || _e === void 0 ? void 0 : _e.storageBucket) ||
                process.env.REACT_APP_FIREBASE_storageBucket ||
                process.env.APP_FIREBASE_storageBucket ||
                process.env.FIREBASE_storageBucket;
            const messagingSenderId = ((_f = globalConfig === null || globalConfig === void 0 ? void 0 : globalConfig.firebase) === null || _f === void 0 ? void 0 : _f.messagingSenderId) ||
                process.env.REACT_APP_FIREBASE_messagingSenderId ||
                process.env.APP_FIREBASE_messagingSenderId ||
                process.env.FIREBASE_messagingSenderId;
            const privateKey = (((_g = globalConfig === null || globalConfig === void 0 ? void 0 : globalConfig.firebase) === null || _g === void 0 ? void 0 : _g.privateKey) ||
                process.env.REACT_APP_FIREBASE_privateKey ||
                process.env.APP_FIREBASE_privateKey ||
                process.env.FIREBASE_privateKey ||
                '').replace(/\\n/g, '\n');
            const clientEmail = ((_h = globalConfig === null || globalConfig === void 0 ? void 0 : globalConfig.firebase) === null || _h === void 0 ? void 0 : _h.clientEmail) ||
                process.env.REACT_APP_FIREBASE_clientEmail ||
                process.env.APP_FIREBASE_clientEmail ||
                process.env.FIREBASE_clientEmail;
            if (apiKey && authDomain && projectId && storageBucket && messagingSenderId) {
                return {
                    apiKey,
                    authDomain,
                    projectId,
                    storageBucket,
                    messagingSenderId,
                    privateKey,
                    databaseURL,
                    clientEmail,
                };
            }
        }
        return this._firebase;
    }
    setFirebase(config) {
        if (!config.apiKey) {
            logger.error('Firebase API Key is not configured.');
        }
        if (!config.authDomain) {
            logger.error('Firebase auth domain is not configured.');
        }
        if (!config.databaseURL) {
            logger.error('Firebase database URL is not configured.');
        }
        if (!config.projectId) {
            logger.error('Firebase project ID is not configured.');
        }
        if (!config.storageBucket) {
            logger.warn('Firebase storage bucket is not configured.');
        }
        if (!config.messagingSenderId) {
            logger.warn('Firebase messaging sender ID is not configured.');
        }
        if (exports.isNode && !config.clientEmail) {
            logger.warn('Firebase client email is not configured.');
        }
        if (exports.isNode && !config.privateKey) {
            logger.warn('Firebase private key is not configured.');
        }
        this._firebase = config;
        if (logger.isDebugEnabled()) {
            const _conf = this.getEnvironmentConfig(true).firebase;
            logger.debug('setFirebase()', _conf);
        }
    }
    get oneSignal() {
        var _a, _b, _c, _d;
        if (!this._oneSignal) {
            // AC Lite (cordova)
            const acLiteAppId = ((_a = globalConfig === null || globalConfig === void 0 ? void 0 : globalConfig.oneSignal) === null || _a === void 0 ? void 0 : _a.acLiteAppId) ||
                process.env.REACT_APP_AC_LITE_ONESIGNAL_APP_ID ||
                process.env.REACT_APP_ONESIGNAL_APP_ID ||
                process.env.ONESIGNAL_AC_LITE_APP_ID ||
                process.env.ONESIGNAL_APP_ID;
            const acLiteAppKey = process.env.ONESIGNAL_AC_LITE_APP_KEY ||
                process.env.ONESIGNAL_APP_KEY ||
                process.env.ONESIGNAL_AUTH;
            const acLiteAndroidChannelId = process.env.ONESIGNAL_AC_LITE_ANDROID_CHANNEL_ID ||
                process.env.ONESIGNAL_ANDROID_CHANNEL_ID;
            const acLiteIosAlertSound = process.env.ONESIGNAL_AC_LITE_IOS_ALERT_SOUND;
            // AC (hybrid app)
            const acAppId = ((_b = globalConfig === null || globalConfig === void 0 ? void 0 : globalConfig.oneSignal) === null || _b === void 0 ? void 0 : _b.acAppId) ||
                process.env.REACT_APP_AC_ONESIGNAL_APP_ID ||
                process.env.ONESIGNAL_AC_APP_ID;
            const acAppKey = process.env.ONESIGNAL_AC_APP_KEY;
            const acAndroidChannelId = process.env.ONESIGNAL_AC_ANDROID_CHANNEL_ID;
            const acIosAlertSound = process.env.ONESIGNAL_AC_IOS_ALERT_SOUND;
            // AC (hybrid app) VoIP
            const acVoIPAppId = ((_c = globalConfig === null || globalConfig === void 0 ? void 0 : globalConfig.oneSignal) === null || _c === void 0 ? void 0 : _c.acVoIPAppId) ||
                process.env.REACT_APP_AC_ONESIGNAL_VOIP_APP_ID ||
                process.env.ONESIGNAL_AC_VOIP_APP_ID;
            const acVoIPAppKey = process.env.ONESIGNAL_AC_VOIP_APP_KEY;
            const acVoIPAndroidChannelId = process.env.ONESIGNAL_AC_VOIP_ANDROID_CHANNEL_ID;
            // webpush
            const webPushAppId = ((_d = globalConfig === null || globalConfig === void 0 ? void 0 : globalConfig.oneSignal) === null || _d === void 0 ? void 0 : _d.webPushAppId) ||
                process.env.REACT_APP_ONESIGNAL_WEBPUSH_APP_ID ||
                process.env.ONESIGNAL_WEB_PUSH_APP_ID;
            const webPushAppKey = process.env.ONESIGNAL_WEB_PUSH_APP_KEY || process.env.ONESIGNAL_WEB_PUSH_AUTH;
            // enabled
            const enabled = ((process.env.REACT_APP_ENABLE_ONE_SIGNAL !== undefined &&
                process.env.REACT_APP_ENABLE_ONE_SIGNAL.toLowerCase() !== 'false') ||
                (process.env.ENABLE_ONE_SIGNAL !== undefined &&
                    process.env.ENABLE_ONE_SIGNAL.toLowerCase() !== 'false') ||
                true) &&
                (acLiteAppId !== undefined || webPushAppId !== undefined);
            const conf = {
                enabled,
                // AC Lite
                acLiteAppId,
                acLiteAppKey,
                acLiteAndroidChannelId,
                acLiteIosAlertSound,
                // AC (hybrid app)
                acAppId,
                acAppKey,
                acAndroidChannelId,
                acIosAlertSound,
                // AC (hybrid app) VoIP
                acVoIPAppId,
                acVoIPAppKey,
                acVoIPAndroidChannelId,
                // Web Push
                webPushAppId,
                webPushAppKey,
            };
            return conf;
        }
        return this._oneSignal;
    }
    setOneSignal(config) {
        this._oneSignal = config;
        if (logger.isDebugEnabled()) {
            const _conf = this.getEnvironmentConfig(true).oneSignal;
            logger.debug('setOneSignal()', _conf);
        }
    }
    get services() {
        if (!this._services) {
            const url = (globalConfig === null || globalConfig === void 0 ? void 0 : globalConfig.servicesURL) ||
                process.env.REACT_APP_servicesURL ||
                process.env.REACT_APP_ATTENTIVE_servicesURL ||
                process.env.APP_servicesURL ||
                process.env.ATTENTIVE_servicesURL ||
                process.env.servicesURL ||
                '/api/v1';
            const port = process.env.AC_API_PORT ||
                process.env.REACT_APP_servicesPort ||
                process.env.APP_servicesPort ||
                process.env.SERVICES_port ||
                process.env.PORT ||
                '3100';
            const config = { url, port };
            return config;
        }
        return this._services;
    }
    setServices(config) {
        this._services = config;
        if (logger.isDebugEnabled()) {
            const _conf = this.getEnvironmentConfig(true).service;
            logger.debug('setServices()', _conf);
        }
    }
    get bestRehaNurseCall() {
        if (!this._bestRehaNurseCall) {
            const url = process.env.bestRehaNurseCallUrl || undefined;
            const auth = process.env.bestRehaNurseCallAuth || undefined;
            const callbackUrl = process.env.bestRehaNurseCallCallbackUrl || undefined;
            const config = { url, auth, callbackUrl };
            return config;
        }
        return this._bestRehaNurseCall;
    }
    setBestRehaNurseCall(config) {
        this._bestRehaNurseCall = config;
        if (logger.isDebugEnabled()) {
            const _conf = this.getEnvironmentConfig(true).service;
            logger.debug('setBestRehaNurseCall()', _conf);
        }
    }
    get admin() {
        if (!this._admin) {
            const username = process.env.REACT_APP_username ||
                process.env.APP_username ||
                process.env.SERVICES_username ||
                '_system.api@attentiveconnect.com';
            const password = process.env.REACT_APP_password ||
                process.env.APP_password ||
                process.env.SERVICES_password ||
                '';
            const config = {
                username,
                password,
            };
            return config;
        }
        return this._admin;
    }
    setAdmin(config) {
        this._admin = config;
        if (logger.isDebugEnabled()) {
            const _conf = this.getEnvironmentConfig(true).admin;
            logger.debug('setAdmin()', _conf);
        }
    }
    get aisleepProxy() {
        if (!this._aisleepProxy) {
            const username = process.env.AISLEEP_PROXY_username ||
                this.admin.username ||
                'aisleep-proxy@attentiveconnect.com';
            const password = process.env.AISLEEP_PROXY_password || this.admin.password;
            const config = {
                username,
                password,
            };
            return config;
        }
        return this._aisleepProxy;
    }
    setAisleepProxy(config) {
        this._aisleepProxy = config;
        if (logger.isDebugEnabled()) {
            const _conf = this.getEnvironmentConfig(true).aisleepProxy;
            logger.debug('setAisleepProxy()', _conf);
        }
    }
    get camera() {
        if (!this._camera) {
            let blur = 0;
            if (process.env.APP_CAMERA_BLUR && !isNaN(parseInt(process.env.APP_CAMERA_BLUR))) {
                blur = parseInt(process.env.APP_CAMERA_BLUR);
            }
            else if (process.env.CAMERA_BLUR && !isNaN(parseInt(process.env.CAMERA_BLUR))) {
                blur = parseInt(process.env.CAMERA_BLUR);
            }
            return {
                blur,
            };
        }
        else {
            return this._camera;
        }
    }
    setCamera(config) {
        this._camera = config;
        if (logger.isDebugEnabled()) {
            const _conf = this.getEnvironmentConfig(true).camera;
            logger.debug('setCamera()', _conf);
        }
    }
    get googleMaps() {
        var _a;
        if (!this._googleMaps) {
            const config = {
                apiKey: ((_a = globalConfig === null || globalConfig === void 0 ? void 0 : globalConfig.googleMaps) === null || _a === void 0 ? void 0 : _a.apiKey) ||
                    process.env.REACT_APP_MAP_API_KEY ||
                    process.env.APP_MAP_API_KEY ||
                    process.env.MAP_API_KEY,
            };
            return config;
        }
        else {
            return this._googleMaps;
        }
    }
    setGooleMaps(config) {
        this._googleMaps = config;
        if (logger.isDebugEnabled()) {
            logger.debug('setGoogleMaps', config);
        }
    }
    get cache() {
        if (!this._cache) {
            //
            // If we're using a cache, then set connection params here.
            //
            // frequency at which we update vitals data
            const vitalsExpirationSeconds = parseInt(process.env.REACT_APP_REDIS_VITALS_EXPIRE_SECONDS ||
                process.env.APP_REDIS_VITALS_EXPIRE_SECONDS ||
                process.env.REDIS_VITALS_EXPIRE_SECONDS ||
                '60');
            // warning is shown if vitals are older than max age
            const vitalsMaxAgeSeconds = parseInt(process.env.REACT_APP_REDIS_VITALS_MAX_AGE_SECONDS ||
                process.env.APP_REDIS_VITALS_MAX_AGE_SECONDS ||
                process.env.REDIS_VITALS_MAX_AGE_SECONDS ||
                '180');
            // frequency at which we update vitals in queues
            const vitalsQExpirationSeconds = parseInt(process.env.REACT_APP_REDIS_VITALS_QUEUE_EXPIRE_SECONDS ||
                process.env.APP_REDIS_VITALS_QUEUE_EXPIRE_SECONDS ||
                process.env.REDIS_VITALS_QUEUE_EXPIRE_SECONDS ||
                '60');
            // frequency at which we update alert data
            const alarmExpirationSeconds = parseInt(process.env.REACT_APP_REDIS_ALARM_EXPIRE_SECONDS ||
                process.env.APP_REDIS_ALARM_EXPIRE_SECONDS ||
                process.env.REDIS_ALARM_EXPIRE_SECONDS ||
                '20');
            const pushHeartbeatSeconds = parseInt(process.env.REACT_APP_PUSH_HEARTBEAT_SECONDS ||
                process.env.APP_PUSH_HEARTBEAT_SECONDS ||
                process.env.PUSH_HEARTBEAT_SECONDS ||
                '10');
            const cache = {
                redisUrl: process.env.REACT_APP_REDIS_URL ||
                    process.env.APP_REDIS_URL ||
                    process.env.REDIS_URL ||
                    undefined,
                vitalsMaxAgeSeconds: isNaN(vitalsMaxAgeSeconds) ? 180 : vitalsMaxAgeSeconds,
                vitalsExpirationSeconds: isNaN(vitalsExpirationSeconds) ? 60 : vitalsExpirationSeconds,
                vitalsQueueExpirationSeconds: isNaN(vitalsQExpirationSeconds)
                    ? 60
                    : vitalsQExpirationSeconds,
                alarmExpirationSeconds: isNaN(alarmExpirationSeconds) ? 60 : alarmExpirationSeconds,
                pushHeartbeatSeconds: isNaN(pushHeartbeatSeconds) ? 10 : pushHeartbeatSeconds,
            };
            return cache;
        }
        else {
            return this._cache;
        }
    }
    getGlobalConfig() {
        if (this.firebase && this.servicesUrl() && this.pathname) {
            return {
                vendor: this.vendor,
                firebase: this.firebase,
                oneSignal: this.oneSignal,
                googleMaps: this.googleMaps,
                servicesURL: this.servicesUrl(),
                pathname: this.pathname,
            };
        }
        else {
            return undefined;
        }
    }
    loadGlobalConfig(config) {
        var _a;
        logger.debug('loadGlobalConfig()');
        if (config.vendor) {
            this.setVendor(config.vendor);
        }
        if (config.firebase) {
            this.setFirebase(config.firebase);
        }
        if ((_a = config.firebase) === null || _a === void 0 ? void 0 : _a.emulator) {
            this.setEmulator(config.firebase.emulator);
        }
        if (config.oneSignal) {
            this.setOneSignal(config.oneSignal);
        }
        if (config.googleMaps) {
            this.setGooleMaps(config.googleMaps);
        }
        if (config.servicesURL) {
            const s = this.services;
            s.url = config.servicesURL;
            this.setServices(s);
        }
        if (config.pathname) {
            this.setPathname(config.pathname);
        }
    }
    get isNode() {
        return exports.isNode;
    }
    get useApi() {
        return exports.useApi;
    }
    isHybrid() {
        return (0, exports.isHybrid)();
    }
    servicesUrl() {
        if (this.services) {
            return this.services.url;
        }
        return undefined;
    }
    getEnvironmentConfig(obfuscate = false) {
        var _a, _b, _c, _d, _e;
        const config = {
            admin: Object.assign({}, this.admin),
            firebase: Object.assign({}, this.firebase),
            service: Object.assign({}, this.services),
            oneSignal: Object.assign({}, this.oneSignal),
            camera: Object.assign({}, this.camera),
            cache: Object.assign({}, this.cache),
            emulator: Object.assign({}, this.emulator),
            vendor: Object.assign({ id: 'macnica' }, this.vendor),
            isNode: Object.assign({}, this.isNode),
            isHybrid: Object.assign({}, this.isHybrid()),
            isEmulator: Object.assign({}, this.isEmulator()),
            useApi: this.useApi,
        };
        if (obfuscate) {
            if ((_a = config.firebase) === null || _a === void 0 ? void 0 : _a.privateKey) {
                config.firebase.privateKey = '...';
            }
            if ((_b = config.admin) === null || _b === void 0 ? void 0 : _b.password) {
                config.admin.password = '...';
            }
            if ((_c = config.oneSignal) === null || _c === void 0 ? void 0 : _c.acLiteAppKey) {
                config.oneSignal.acLiteAppKey = '...';
            }
            if ((_d = config.oneSignal) === null || _d === void 0 ? void 0 : _d.acAppKey) {
                config.oneSignal.acAppKey = '...';
            }
            if ((_e = config.oneSignal) === null || _e === void 0 ? void 0 : _e.webPushAppKey) {
                config.oneSignal.webPushAppKey = '...';
            }
        }
        return config;
    }
    toString() {
        return JSON.stringify(this.getEnvironmentConfig(true), undefined, 2);
    }
}
exports.Environment = Environment;
const env = () => Firebase_1.FirebaseRuntime.instance.env;
// logger.debug('Environment', env.getEnvironmentConfig(true));
exports.default = env;
