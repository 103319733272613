import * as models from 'attentive-connect-store/dist/models';
import Camera from 'attentive-connect-store/dist/models/Camera';
import NurseCall from 'attentive-connect-store/dist/models/NurseCall';

import * as data from '../../data';
import { PushStatus } from '../../push/Push';

// Use const enums for better autocompletion of action type names. These will
// be compiled away leaving only the final value in your compiled code.
export enum ContextActionTypes {
  SET_CENTER = '@@context/SET_CENTER',
  CLEAR_CENTER = '@@context/CLEAR_CENTER',

  SET_USER = '@@context/SET_USER',

  SET_RESIDENT = '@@context/SET_RESIDENT',
  CLEAR_RESIDENT = '@@context/CLEAR_RESIDENT',

  SET_ACTIVE_RESIDENTS = '@@context/SET_ACTIVE_RESIDENTS',
  CLEAR_ACTIVE_RESIDENTS = '@@context/CLEAR_ACTIVE_RESIDENTS',

  SET_RESIDENT_POSITION = '@@context/SET_RESIDENT_POSITION',
  CLEAR_RESIDENT_POSITION = '@@context/CLEAR_RESIDENT_POSITION',

  SET_OPEN_ALERTS = '@@context/SET_OPEN_ALERTS',
  CLEAR_OPEN_ALERTS = '@@context/CLEAR_OPEN_ALERTS',

  SET_ACTIVE_ALERTS = '@@context/SET_ACTIVE_ALERTS',
  CLEAR_ACTIVE_ALERTS = '@@context/CLEAR_ACTIVE_ALERTS',

  SET_ALERT = '@@context/SET_ALERT',
  CLEAR_ALERT = '@@context/CLEAR_ALERT',

  SET_ALERT_POSITION = '@@context/SET_ALERT_POSITION',
  CLEAR_ALERT_POSITION = '@@context/CLEAR_ALERT_POSITION',

  SET_ALERT_TAB = '@@context/SET_ALERT_TAB',
  CLEAR_ALERT_TAB = '@@context/CLEAR_ALERT_TAB',

  SET_RESIDENT_TAB = '@@context/SET_RESIDENT_TAB',
  CLEAR_RESIDENT_TAB = '@@context/CLEAR_RESIDENT_TAB',

  SET_SENSORS = '@@context/SET_SENSORS',
  CLEAR_SENSORS = '@@context/CLEAR_SENSORS',

  SET_SENSOR = '@@context/SET_SENSOR',
  CLEAR_SENSOR = '@@context/CLEAR_SENSOR',

  SET_SENSOR_POSITION = '@@context/SET_SENSOR_POSITION',
  CLEAR_SENSOR_POSITION = '@@context/CLEAR_SENSOR_POSITION',

  SET_DEVICES = '@@context/SET_DEVICES',
  CLEAR_DEVICES = '@@context/CLEAR_DEVICES',

  SET_DEVICE = '@@context/SET_DEVICE',
  CLEAR_DEVICE = '@@context/CLEAR_DEVICE',

  SET_DEVICE_POSITION = '@@context/SET_DEVICE_POSITION',
  CLEAR_DEVICE_POSITION = '@@context/CLEAR_DEVICE_POSITION',

  SET_CREATE_DEVICE_TYPE = '@@context/SET_CREATE_DEVICE_TYPE',
  CLEAR_CREATE_DEVICE_TYPE = '@@context/CLEAR_CREATE_DEVICE_TYPE',

  SET_CAMERAS = '@@context/SET_CAMERAS',
  CLEAR_CAMERAS = '@@context/CLEAR_CAMERAS',

  SET_CAMERA = '@@context/SET_CAMERA',
  CLEAR_CAMERA = '@@context/CLEAR_CAMERA',

  SET_CAMERA_POSITION = '@@context/SET_CAMERA_POSITION',
  CLEAR_CAMERA_POSITION = '@@context/CLEAR_CAMERA_POSITION',

  SET_NURSECALLS = '@@context/SET_NURSECALLS',
  CLEAR_NURSECALLS = '@@context/CLEAR_NURSECALLS',

  SET_NURSECALL = '@@context/SET_NURSECALL',
  CLEAR_NURSECALL = '@@context/CLEAR_NURSECALL',

  SET_NURSECALL_POSITION = '@@context/SET_NURSECALL_POSITION',
  CLEAR_NURSECALL_POSITION = '@@context/CLEAR_NURSECALL_POSITION',

  SET_CREATE_NURSECALL_TYPE = '@@context/SET_CREATE_NURSECALL_TYPE',
  CLEAR_CREATE_NURSECALL_TYPE = '@@context/CLEAR_CREATE_NURSECALL_TYPE',

  SET_DRAWER_IS_OPEN = '@@context/SET_DRAWER_IS_OPEN',

  SET_SENSORS_STATE_SENSORS = '@@context/SET_SENSORS_STATE_SENSORS',
  SET_SENSORS_STATE = '@@context/SET_SENSORS_STATE',
  CLEAR_SENSORS_STATE = '@@context/CLEAR_SENSORS_STATE',

  ENABLE_SENSORS_STATE_POLLING = '@@context/ENABLE_SENSORS_STATE_POLLING',
  DISABLE_SENSORS_STATE_POLLING = '@@context/DISABLE_SENSORS_STATE_POLLING',

  SET_REPORT_SENSOR_TYPE = '@@context/SET_REPORT_SENSOR_TYPE',
  CLEAR_REPORT_SENSOR_TYPE = '@@context/CLEAR_REPORT_SENSOR_TYPE',

  SET_SELECT_ALERT_TYPE = '@@context/SET_SELECT_ALERT_TYPE',
  CLEAR_SELECT_ALERT_TYPE = '@@context/CLEAR_SELECT_ALERT_TYPE',

  ENABLE_ALERT_LISTENERS = '@@context/ENABLE_ALERT_LISTENERS',
  DISABLE_ALERT_LISTENERS = '@@context/DISABLE_ALERT_LISTENERS',

  SET_PUSH_STATUS = '@@context/SET_PUSH_STATUS',
}

export interface ContextState {
  readonly userPerson?: models.UserPerson;
  readonly center?: models.CareCenter | null;

  readonly resident?: models.Resident | null;
  readonly residentTab?: number;
  readonly activeResidents?: data.sensors.ResidentViewData[];
  readonly residentPosition?: number;

  readonly openAlerts?: models.AlertWithSensorDetail[];
  readonly activeAlerts?: data.alerts.AlertViewData[];
  readonly alert?: data.alerts.AlertViewData;
  readonly alertPosition?: number;
  readonly alertTab?: number;

  readonly allSensorsAlertSettings?: data.sensors.AlertSettingsViewData[];
  readonly alertSettingsViewData?: data.sensors.AlertSettingsViewData;
  readonly sensorPosition?: number;

  readonly allDevices?: data.sensors.SensorDeviceData[];
  readonly currentDevice?: data.sensors.SensorDeviceData;
  readonly currentDevicePosition?: number;
  readonly createDeviceType?: models.SensorType;

  readonly allCameras?: Camera[];
  readonly currentCamera?: Camera;
  readonly currentCameraPosition?: number;

  readonly allNurseCall?: NurseCall[];
  readonly currentNurseCall?: NurseCall;
  readonly currentNurseCallPosition?: number;
  readonly createNurseCallType?: models.NurseCallType;

  readonly drawerIsOpen: boolean;

  readonly sensorsStatePolling: boolean;
  readonly sensorsState: data.sensors.SensorStateData[];
  readonly sensorsStateSensors: data.sensors.SensorStateData[];

  readonly reportSensorType?: models.SensorType;

  readonly selectAlertType?: models.AlertType;
  readonly listenAlerts: boolean;

  readonly pushStatus: PushStatus;
}
